import { type Locale } from './language'

/**
 * Gets Shopify shop ID local storage key.
 */
export const getShopifyShopIdStorageKey = (locale: Locale) =>
  `shopify_shop_id_${locale}`

/**
 * Gets Shopify cart ID local storage key.
 */
export const getShopifyCartIdStorageKey = (locale: Locale) =>
  `shopify_cart_id_${locale}`
