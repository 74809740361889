import {
  type SanityCompany,
  type SanityCustomerCollectionDiscount,
  type SanityCustomerProductDiscount,
} from '@data/sanity/queries/types/customer'
import { type VariantLineItem } from '@lib/cart/types'
import { getDocumentPercentDiscount } from '@lib/product/price'
import { type DiscountItem, type DocumentDiscount } from './types'

/**
 * Gets product discounts from customer product discounts.
 */
export const getProductDiscounts = (
  customerProductDiscounts?: SanityCustomerProductDiscount[]
) => {
  return customerProductDiscounts?.map((customerProductDiscount) => {
    const documentDiscount: DocumentDiscount = {
      id: customerProductDiscount.product._id,
      type: customerProductDiscount.product._type,
      percentDiscount: customerProductDiscount.percentDiscount,
    }

    return documentDiscount
  })
}

/**
 * Gets collection discounts from customer collection discounts.
 */
export const getCollectionDiscounts = (
  customerCollectionDiscounts?: SanityCustomerCollectionDiscount[]
) => {
  return customerCollectionDiscounts?.map((customerCollectionDiscount) => {
    const documentDiscount: DocumentDiscount = {
      id: customerCollectionDiscount.collection._id,
      type: customerCollectionDiscount.collection._type,
      percentDiscount: customerCollectionDiscount.percentDiscount,
    }

    return documentDiscount
  })
}

/**
 * Gets product discount items based on variant line items.
 */
export const getProductDiscountItems = (
  variantLineItems: VariantLineItem[],
  company?: SanityCompany
) => {
  if (typeof company === 'undefined') {
    return []
  }

  const discountAmounts: number[] = []
  const discountTitles: string[] = []

  variantLineItems.forEach((variantLineItem) => {
    const price = variantLineItem.price * variantLineItem.quantity

    const productDiscounts = getProductDiscounts(company.productDiscounts)
    const collectionDiscounts = getCollectionDiscounts(
      company.collectionDiscounts
    )
    const productPercentDiscount = getDocumentPercentDiscount(
      productDiscounts ?? [],
      'product',
      variantLineItem.productId ? [variantLineItem.productId] : []
    )
    const combinedListingPercentDiscount = getDocumentPercentDiscount(
      productDiscounts ?? [],
      'productCombinedListing',
      variantLineItem.productId ? [variantLineItem.productId] : []
    )
    const collectionPercentDiscount = getDocumentPercentDiscount(
      collectionDiscounts ?? [],
      'collection',
      variantLineItem.collectionIds ?? []
    )

    // 1.1. Product discount
    if (typeof productPercentDiscount !== 'undefined') {
      const productDiscountRate = productPercentDiscount / 100
      const productDiscount = price * productDiscountRate
      discountAmounts.push(productDiscount)
      discountTitles.push(company.name)
      return
    }

    // 1.2. Combined listing discount
    if (typeof combinedListingPercentDiscount !== 'undefined') {
      const combinedListingDiscountRate = combinedListingPercentDiscount / 100
      const combinedListingDiscount = price * combinedListingDiscountRate
      discountAmounts.push(combinedListingDiscount)
      discountTitles.push(company.name)
      return
    }

    // 2. Collection discount
    if (typeof collectionPercentDiscount !== 'undefined') {
      const collectionDiscountRate = collectionPercentDiscount / 100
      const collectionDiscount = price * collectionDiscountRate
      discountAmounts.push(collectionDiscount)
      discountTitles.push(company.name)
      return
    }

    // 3. Company discount
    if (typeof company.percentDiscount !== 'undefined') {
      const companyDiscountRate = company.percentDiscount / 100
      const companyDiscount = price * companyDiscountRate
      discountAmounts.push(companyDiscount)
      discountTitles.push(company.name)
      return
    }
  })

  const amount = discountAmounts.reduce(
    (total, discountAmount) => total + discountAmount,
    0
  )
  const title = discountTitles
    .filter((value, index, array) => array.indexOf(value) === index)
    .join(', ')

  const discountItem: DiscountItem = {
    id: 'product-discount',
    type: 'product',
    amount,
    title,
  }

  return [discountItem]
}
