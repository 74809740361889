import { useCallback, useContext, useEffect, useRef } from 'react'

import {
  type CartLineInput,
  type CartLineUpdateInput,
} from '@data/shopify/storefront/types'
import { getAddressFormValuesKey, getCartAddressKey } from '@lib/address'
import { type Locale } from '@lib/language'
import { LanguageContext } from '@lib/language-context'
import { getShopifyCartIdStorageKey } from '@lib/local-storage'
import { ShopContext } from '@lib/shop-context'
import { getTaxCartAttributes } from '@lib/shopify/attribute'
import {
  addLineItemsToShopifyCart,
  removeLineItemsFromShopifyCart,
  updateLineItemsInShopifyCart,
  updateShopifyCartAttrbites,
  updateShopifyCartNote,
} from '@lib/shopify/graphql/cart'
import { type ShopifyClient } from '@lib/shopify/graphql/client'
import { getShopifyGlobalId } from '@lib/shopify/helpers'
import { StringsContext } from '@lib/strings-context'
import { UserContext } from '@lib/user/context'
import { type UserAddress, type UserCustomer } from '@lib/user/types'
import { CartContext } from './context'
import {
  addCartDeliveryAddress,
  removeCartDeliveryAddress,
  setCartBuyerIdentity,
  updateCartDeliveryAddress,
  validateCart,
} from './helpers'
import { updateShopifyCartDiscount } from './request'
import {
  type Cart,
  type CartFormValues,
  type CartVariantLineItem,
} from './types'

/**
 * Returns a hook that adds an item to cart.
 */
export const useAddItemsToCart = () => {
  const {
    cart,
    toggleCart,
    saveCart,
    setIsCartUpdating,
    setCartAddingProductIds,
  } = useContext(CartContext)
  const { locale } = useContext(LanguageContext)
  const { shopifyStorefrontGraphQlClient } = useContext(ShopContext)

  return useCallback(
    async (variantLineItems: CartVariantLineItem[]): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      try {
        if (!shopifyStorefrontGraphQlClient) {
          throw new Error('Shopify Storefront API client missing')
        }

        // Compare current cart ID with saved cart ID
        if (typeof window !== 'undefined') {
          const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
          const cartId = localStorage.getItem(shopifyCartIdStorageKey)

          if (cart.id !== cartId) {
            return false
          }
        }

        setCartAddingProductIds(variantLineItems.map((item) => item.id))
        setIsCartUpdating(true)

        // Add line items to cart
        const lines = variantLineItems.map((variantLineItem) => {
          const cartLineInput: CartLineInput = {
            merchandiseId: getShopifyGlobalId(
              'ProductVariant',
              variantLineItem.id
            ),
            quantity: variantLineItem.quantity,
            attributes: variantLineItem.attributes ?? [],
          }
          return cartLineInput
        })
        await addLineItemsToShopifyCart(
          locale,
          shopifyStorefrontGraphQlClient,
          cart.id,
          lines
        )

        // Deprecated. Encourage clients to use GTM instead.
        // // Trigger add to cart Facebook Conversions API event
        // if (settings?.facebookEvents) {
        //   // Get variant details from Sanity
        //   const sanityClient = getSanityClient()
        //   const variantIds = variantLineItems.map(
        //     (variantLineItem) => variantLineItem.id
        //   )
        //   const productVariants = await getProductVariants(
        //     sanityClient,
        //     locale,
        //     variantIds
        //   )
        //   productVariants.forEach(async (variant) => {
        //     await triggerAddToCartFacebookEvent(locale, variant)
        //   })
        // }

        // Update cart discount codes
        const newCart = await updateShopifyCartDiscount(locale, cart.id)

        saveCart(newCart)

        setCartAddingProductIds([])
        setIsCartUpdating(false)
        toggleCart(false)

        return true
      } catch (error) {
        console.log(error)

        setCartAddingProductIds([])
        setIsCartUpdating(false)
        return false
      }
    },
    [
      cart.id,
      locale,
      saveCart,
      setCartAddingProductIds,
      setIsCartUpdating,
      shopifyStorefrontGraphQlClient,
      toggleCart,
    ]
  )
}

/**
 * Returns a hook that updates an item in cart.
 */
export const useUpdateCartItem = () => {
  const { cart, saveCart, setIsCartUpdating } = useContext(CartContext)
  const { locale } = useContext(LanguageContext)
  const { shopifyStorefrontGraphQlClient } = useContext(ShopContext)

  return useCallback(
    async (ids: string[], quantity: number): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      try {
        if (!shopifyStorefrontGraphQlClient) {
          throw new Error('Shopify Storefront API client missing')
        }

        // Compare current cart ID with saved cart ID
        if (typeof window !== 'undefined') {
          const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
          const cartId = localStorage.getItem(shopifyCartIdStorageKey)

          if (cart.id !== cartId) {
            return false
          }
        }

        setIsCartUpdating(true)

        // Update cart line items
        const lines = ids.map((id) => {
          const cartLineUpdateInput: CartLineUpdateInput = {
            id,
            quantity,
          }
          return cartLineUpdateInput
        })
        await updateLineItemsInShopifyCart(
          locale,
          shopifyStorefrontGraphQlClient,
          cart.id,
          lines
        )

        // Update cart discount codes
        const newCart = await updateShopifyCartDiscount(locale, cart.id)

        saveCart(newCart)

        setIsCartUpdating(false)

        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    [
      cart.id,
      locale,
      saveCart,
      setIsCartUpdating,
      shopifyStorefrontGraphQlClient,
    ]
  )
}

/**
 * Returns a hook that removes an item from cart.
 */
export const useRemoveItemFromCart = () => {
  const { cart, saveCart, setIsCartUpdating } = useContext(CartContext)
  const { locale } = useContext(LanguageContext)
  const { shopifyStorefrontGraphQlClient } = useContext(ShopContext)

  return useCallback(
    async (ids: string[]): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      try {
        if (!shopifyStorefrontGraphQlClient) {
          throw new Error('Shopify Storefront API client missing')
        }

        // Compare current cart ID with saved cart ID
        if (typeof window !== 'undefined') {
          const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
          const cartId = localStorage.getItem(shopifyCartIdStorageKey)

          if (cart.id !== cartId) {
            return false
          }
        }

        setIsCartUpdating(true)

        // Remove line item from Shopify cart
        await removeLineItemsFromShopifyCart(
          locale,
          shopifyStorefrontGraphQlClient,
          cart.id,
          ids
        )

        // Update cart discount codes
        const newCart = await updateShopifyCartDiscount(locale, cart.id)

        saveCart(newCart)

        setIsCartUpdating(false)

        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    [
      cart.id,
      locale,
      saveCart,
      setIsCartUpdating,
      shopifyStorefrontGraphQlClient,
    ]
  )
}

/**
 * Returns a hook that prepares cart for order process.
 */
export const usePrepareCart = () => {
  const { cart } = useContext(CartContext)
  const { locale } = useContext(LanguageContext)
  const { countryCode, shopifyStorefrontGraphQlClient } =
    useContext(ShopContext)
  const strings = useContext(StringsContext)

  return useCallback(
    async (values: CartFormValues) => {
      if (!shopifyStorefrontGraphQlClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      // Compare current cart ID with saved cart ID
      if (typeof window !== 'undefined') {
        const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
        const cartId = localStorage.getItem(shopifyCartIdStorageKey)

        if (cart.id !== cartId) {
          return {
            errors: {
              cart: 'Cart ID mismatch',
            },
          }
        }
      }

      // Validate cart form
      const validateCartResult = await validateCart(strings, values)

      if (cart?.id && Object.entries(validateCartResult.errors).length === 0) {
        // Update cart attributes
        const taxCartAttributes = getTaxCartAttributes(
          values,
          validateCartResult.vatIdCountryCode !== countryCode
        )

        try {
          await updateShopifyCartAttrbites(
            locale,
            shopifyStorefrontGraphQlClient,
            cart.id,
            [...taxCartAttributes]
          )

          // Update cart note
          await updateShopifyCartNote(
            locale,
            shopifyStorefrontGraphQlClient,
            cart.id,
            values.comment ?? ''
          )
        } catch (error) {
          console.log(error)
        }
      }

      return {
        errors: validateCartResult.errors,
      }
    },
    [cart.id, countryCode, locale, shopifyStorefrontGraphQlClient, strings]
  )
}

/**
 * Returns a hook that updates cart buyer identity and shipping address based on current user.
 */
export const useUpdateCartShippingSettings = (
  cart: Cart,
  saveCart: (cart?: Cart) => void
) => {
  const { locale } = useContext(LanguageContext)
  const { shopifyStorefrontGraphQlClient } = useContext(ShopContext)
  const { user } = useContext(UserContext)

  const lastActionRef = useRef('')

  /**
   * Automatically updates cart buyer identity.
   */
  const syncCartBuyerIdentity = useCallback(
    async (
      shopifyStorefrontGraphQlClient: ShopifyClient,
      locale: Locale,
      cart: Cart,
      saveCart: (cart?: Cart) => void,
      customerAccessToken?: string,
      customer?: UserCustomer
    ) => {
      // Check if user is logged in and has Shopify customer data
      if (!customerAccessToken || !customer) {
        return
      }

      const shopifyCustomerId = getShopifyGlobalId(
        'Customer',
        customer.customerId
      )

      // Check if cart's customer ID matches user's customer ID
      if (cart.customerId === shopifyCustomerId) {
        return
      }

      const newCart = await setCartBuyerIdentity(
        locale,
        shopifyStorefrontGraphQlClient,
        cart.id,
        customerAccessToken
      )
      saveCart(newCart)
    },
    []
  )

  /**
   * Automatically updates cart shipping address.
   */
  const syncCartShippingAddress = useCallback(
    async (
      shopifyStorefrontGraphQlClient: ShopifyClient,
      locale: Locale,
      cart: Cart,
      saveCart: (cart?: Cart) => void,
      addresses: UserAddress[]
    ) => {
      const defaultUserAddress = addresses.find((address) => address.isDefault)
      const firstUserAddress = addresses.length > 0 ? addresses[0] : undefined
      const userAddress = defaultUserAddress ?? firstUserAddress
      const userAddressKey = userAddress
        ? getAddressFormValuesKey(userAddress.countryCode, userAddress.values)
        : null
      const selectedShippingAddress = cart.shippingAddresses?.find(
        (shippingAddress) => shippingAddress.selected
      )
      const shippingAddressKey = selectedShippingAddress
        ? getCartAddressKey(selectedShippingAddress)
        : null

      // Check if cart's shipping address is not set
      if (userAddress && userAddressKey && !shippingAddressKey) {
        const addAction = `add:${cart.id},${userAddress.id}`

        if (lastActionRef.current !== addAction) {
          lastActionRef.current = addAction

          const newCart = await addCartDeliveryAddress(
            locale,
            shopifyStorefrontGraphQlClient,
            cart.id,
            userAddress.id
          )
          saveCart(newCart)
        }

        return
      }

      // Check if cart's shipping address doesn't match user's address
      if (
        userAddress &&
        userAddressKey &&
        shippingAddressKey &&
        selectedShippingAddress &&
        userAddressKey !== shippingAddressKey
      ) {
        const updateAction = `update:${cart.id},${selectedShippingAddress.id},${userAddress.id}`

        if (lastActionRef.current !== updateAction) {
          lastActionRef.current = updateAction

          const newCart = await updateCartDeliveryAddress(
            locale,
            shopifyStorefrontGraphQlClient,
            cart.id,
            selectedShippingAddress.id,
            userAddress.id
          )
          saveCart(newCart)
        }

        return
      }

      // Check if user's address is not set
      if (!userAddressKey && shippingAddressKey && selectedShippingAddress) {
        const removeAction = `remove:${cart.id},${selectedShippingAddress.id}`

        if (lastActionRef.current !== removeAction) {
          lastActionRef.current = removeAction

          const newCart = await removeCartDeliveryAddress(
            locale,
            shopifyStorefrontGraphQlClient,
            cart.id,
            selectedShippingAddress.id
          )
          saveCart(newCart)
        }

        return
      }
    },
    []
  )

  useEffect(() => {
    if (!shopifyStorefrontGraphQlClient || !cart.id) {
      return
    }

    const customerAccessToken = user?.token
    const customer = user?.customer
    const addresses = user?.addresses ?? []

    const syncCartSettings = async () => {
      // Use await here to avoid internal error in Shopify from possible race conditions in mutations
      await syncCartBuyerIdentity(
        shopifyStorefrontGraphQlClient,
        locale,
        cart,
        saveCart,
        customerAccessToken,
        customer
      )
      await syncCartShippingAddress(
        shopifyStorefrontGraphQlClient,
        locale,
        cart,
        saveCart,
        addresses
      )
    }
    syncCartSettings()
  }, [
    cart,
    locale,
    saveCart,
    shopifyStorefrontGraphQlClient,
    syncCartBuyerIdentity,
    syncCartShippingAddress,
    user,
  ])
}
