import cx from 'classnames'
import NextImage from 'next/image'
import {
  type CSSProperties,
  type MouseEvent,
  forwardRef,
  useState,
} from 'react'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { borderRadiusClassMap } from '@lib/border'
import {
  type ImageDimensions,
  getImageDimensions,
  getSanityImageLoader,
} from '@lib/image'

import Link from '@components/link'

const DEFAULT_ZOOM_QUALITY = 90
const DEFAULT_ZOOM_SCALE = 2.2

interface PhotoProps {
  image: SanityImageFragment
  width?: number
  height?: number
  sizes?: string
  fill?: boolean
  priority?: boolean
  unoptimized?: boolean
  quality?: number
  className?: string
  imageClassName?: string
  style?: CSSProperties
  imageStyle?: CSSProperties
  showCaption?: boolean
  showPlaceholder?: boolean
  fadeIn?: boolean
  enableMagnify?: boolean
}

const Photo = forwardRef<HTMLElement, PhotoProps>(
  (
    {
      image,
      width,
      height,
      sizes,
      fill,
      priority,
      unoptimized,
      quality = 75,
      className,
      imageClassName,
      style,
      imageStyle,
      showCaption = true,
      showPlaceholder = true,
      fadeIn,
      enableMagnify = false,
    },
    ref
  ) => {
    const [isLoaded, setIsLoaded] = useState(false)

    if (!image || !image.asset) {
      return null
    }

    const dimensions: ImageDimensions | undefined = !fill
      ? getImageDimensions(image, width, height)
      : undefined

    const placeholder = showPlaceholder
      ? (image.asset.metadata.lqip as `data:image/${string}`)
      : undefined

    // Simplistic zoom ratio calculation (ideally would be based on rendered size)
    const dynamicZoomScale = dimensions?.width ? dimensions?.width / 700 : 1
    const zoomScale = enableMagnify
      ? Math.round(Math.min(dynamicZoomScale, DEFAULT_ZOOM_SCALE) * 10) / 10
      : 1

    const updateZoomOrigin = (e: MouseEvent<HTMLImageElement>) => {
      if (enableMagnify) {
        const rect = e.currentTarget.getBoundingClientRect()
        const offsetX = e.clientX - rect.left
        const offsetY = e.clientY - rect.top
        const percentX = (offsetX / rect.width) * 100
        const percentY = (offsetY / rect.height) * 100
        e.currentTarget.style.transformOrigin = `${percentX}% ${percentY}%`
      }
    }

    const figure = (
      <figure ref={ref} className={cx('photo', className)} style={style}>
        <NextImage
          src={JSON.stringify(image)}
          loader={getSanityImageLoader}
          width={dimensions?.width}
          height={dimensions?.height}
          sizes={sizes}
          fill={!!fill}
          unoptimized={!!unoptimized}
          priority={!!image.priority || !!priority}
          quality={enableMagnify ? DEFAULT_ZOOM_QUALITY : quality}
          placeholder={placeholder}
          alt={image.alt ?? ''}
          className={cx(
            'block overflow-hidden transition-opacity duration-500',
            image.borderRadius
              ? borderRadiusClassMap[image.borderRadius ?? '']
              : '',
            {
              'opacity-0': fadeIn && !isLoaded,
              [`transition-transform duration-300 ease-in-out'`]: enableMagnify,
            },
            imageClassName
          )}
          style={{
            ...(imageStyle || {}),
          }}
          onMouseEnter={(e) => {
            if (enableMagnify) {
              e.currentTarget.style.transform = `scale(${zoomScale})`
            }
          }}
          onMouseMove={updateZoomOrigin}
          onMouseLeave={(e) => {
            if (enableMagnify) {
              e.currentTarget.style.transform = 'scale(1)'
            }
          }}
          onLoad={() => setIsLoaded(true)}
        />

        {showCaption && image.caption && (
          <figcaption className="text-current text-sm my-2">
            {image.caption}
          </figcaption>
        )}
      </figure>
    )

    if (image.link) {
      return (
        <Link link={image.link} className="no-underline">
          {figure}
        </Link>
      )
    }

    return figure
  }
)

Photo.displayName = 'Photo'

export default Photo
