import { CustomerErrorCode } from '@data/shopify/storefront/types'
import { type ParseResults, parseResultsOk } from '@lib/request'

export interface ShopifyUserError {
  code?: string | null
  field?: string[] | null
  message: string
}

export interface ShopifyWarning {
  code?: string | null
  target?: string | null
  message: string
}

/**
 * Gets field name from Shopify GraphQL API result error.
 */
export const getGrqphQlErrorFieldName = (errorField?: string[] | null) => {
  const count = errorField?.length ?? 0

  if (errorField && count > 0) {
    return errorField[count - 1]
  }
}

/**
 * Gets validation results from Shopify user errors.
 */
export const parseUserErrors = (userErrors?: ShopifyUserError[]) => {
  const results: ParseResults = {
    ...parseResultsOk,
    errorCount: userErrors?.length ?? 0,
  }

  userErrors?.forEach((userError, index) => {
    const fieldName = getGrqphQlErrorFieldName(userError.field)
    results.fieldErrors[fieldName ?? index] = userError.message

    if (userError.code === CustomerErrorCode.UnidentifiedCustomer) {
      results.status = 'invalid_credentials'
    }
  })

  return results
}

/**
 * Gets an error message from Shopify user error list.
 */
export const getUserErrorMessage = (userErrors?: ShopifyUserError[]) => {
  const errorList = userErrors ?? []

  if (errorList.length === 0) {
    return
  }

  return errorList
    .map((error) =>
      !error.field
        ? error.message
        : `[${error.field.join('.')}] ${error.message}`
    )
    .join('; ')
}

/**
 * Gets a warning message from Shopify warning list.
 */
export const getWarningMessage = (warnings?: ShopifyWarning[]) => {
  const warningList = warnings ?? []

  if (warningList.length === 0) {
    return
  }

  return warningList
    .map((warning) =>
      !warning.target
        ? warning.message
        : `[${warning.target}] ${warning.message}`
    )
    .join('; ')
}
