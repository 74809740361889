import { useCallback, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'

import { LanguageContext } from '@lib/language-context'
import { parseResultsUnknownError } from '@lib/request'
import { ShopContext } from '@lib/shop-context'
import { getShopifyCustomerWithOrders } from '@lib/shopify/graphql/customers'
import { parseShopifyOrder } from '@lib/shopify/order'
import { UserContext } from './context'
import { getUser, loginUser, logoutUser } from './request'
import {
  type LiveUser,
  type LoginFormValues,
  type StoredUser,
  type UserKey,
  type UserOrder,
} from './types'

/**
 * Live user data and mutation hook.
 */
export const useUser = () => {
  const { locale } = useContext(LanguageContext)

  const userKey: UserKey = ['/api/auth/user', locale]
  const { data, mutate } = useSWR<StoredUser>(
    userKey,
    async ([, locale]: UserKey) => {
      return await getUser(locale)
    },
    {
      errorRetryCount: 3,
    }
  )

  const liveUser: LiveUser = {
    user: data,
    mutateUser: mutate,
  }
  return liveUser
}

/**
 * Returns a method that logs in a user.
 */
export const useLoginUser = () => {
  const { locale } = useContext(LanguageContext)
  const { mutateUser } = useContext(UserContext)

  return useCallback(
    async (loginFormValues: LoginFormValues) => {
      try {
        const { user, ...parseResults } = await loginUser(
          locale,
          loginFormValues
        )

        mutateUser(user)

        return parseResults
      } catch (error) {
        console.log(error)

        return parseResultsUnknownError
      }
    },
    [locale, mutateUser]
  )
}

/**
 * Returns a method that logs out a user.
 */
export const useLogoutUser = () => {
  const { locale } = useContext(LanguageContext)
  const { user, mutateUser } = useContext(UserContext)

  return useCallback(
    async (onSuccess?: () => void) => {
      const response = await logoutUser(locale, {
        token: user?.token,
      })

      // Update SWR cache
      mutateUser(response.user)

      if (onSuccess) {
        onSuccess()
      }
    },
    [locale, mutateUser, user]
  )
}

/**
 * User order list hook.
 */
export const useUserOrderList = () => {
  const { locale } = useContext(LanguageContext)
  const { shopifyStorefrontGraphQlClient } = useContext(ShopContext)
  const { user } = useContext(UserContext)

  const [orders, setOrders] = useState<UserOrder[]>([])
  const [orderCursor, setOrderCursor] = useState<string | null>(null)
  const [hasMoreOrders, setHasMoreOrders] = useState(false)
  const [isLoadingOrders, setIsLoadingOrders] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)

  const loadOrders = useCallback(async () => {
    if (!user?.token) {
      return
    }

    if (!shopifyStorefrontGraphQlClient) {
      throw new Error('Shopify Storefront API client missing')
    }

    setIsLoadingOrders(true)

    try {
      const orderPageSize = 10
      const shopifyCustomer = await getShopifyCustomerWithOrders(
        locale,
        shopifyStorefrontGraphQlClient,
        user.token,
        orderCursor,
        orderPageSize
      )
      const newOrders =
        shopifyCustomer?.orders?.edges
          ?.map((edge) => edge.node)
          ?.filter(Boolean)
          ?.sort(
            (order1, order2) =>
              Date.parse(order2.processedAt) - Date.parse(order1.processedAt)
          )
          ?.map(parseShopifyOrder) ?? []

      setOrders((currentOrders) => [...currentOrders, ...newOrders])
      setOrderCursor(
        shopifyCustomer?.orders?.edges?.map((edge) => edge.cursor)?.pop() ??
          null
      )
      setHasMoreOrders(!!shopifyCustomer?.orders?.pageInfo?.hasNextPage)
    } catch (error) {
      console.log(error)

      setOrderCursor(null)
      setHasMoreOrders(false)
    }

    setIsLoadingOrders(false)
  }, [locale, orderCursor, shopifyStorefrontGraphQlClient, user?.token])

  // Initialize order list
  useEffect(() => {
    if (!isInitialized && user?.token) {
      // Load first page
      loadOrders()
      setIsInitialized(true)
    }

    if (isInitialized && !user?.token) {
      // Clear orders
      setOrders([])
      setOrderCursor(null)
      setHasMoreOrders(false)
      setIsInitialized(false)
    }
  }, [isInitialized, loadOrders, user?.token])

  return {
    orders,
    hasMoreOrders,
    loadOrders,
    isLoadingOrders,
  }
}
