import { type NextApiRequest, type NextApiResponse } from 'next'

import { ProductValidationError } from './product/types'

export type ParseStatus =
  | 'ok'
  | 'invalid_credentials'
  | 'missing_credentials'
  | 'missing_company'
  | 'unknown_error'

export interface ErrorMessages {
  [key: string]: string
}

export interface ParseResults {
  fieldErrors: ErrorMessages
  errorCount: number
  status: ParseStatus
}

export interface KlaviyoError {
  response?: {
    data?: {
      errors?: unknown
    }
  }
}

export class RequestMethodOptionsError extends Error {}

export class RequestMethodValidationError extends Error {}

export class RequestStopError extends Error {}

export class RequestUnprocessableContentError extends Error {}

export class ParseError extends Error {
  public parseResults: ParseResults

  constructor(parseResults: ParseResults) {
    super(Object.values(parseResults.fieldErrors).join('; '))
    Object.setPrototypeOf(this, ParseError.prototype)

    this.parseResults = parseResults
  }
}

export const parseResultsOk: ParseResults = {
  fieldErrors: {},
  errorCount: 0,
  status: 'ok',
}

export const parseResultsUnknownError: ParseResults = {
  fieldErrors: {},
  errorCount: 0,
  status: 'unknown_error',
}

/**
 * Parses required page request parameter.
 */
export function parseRequiredParameter<T = string>(
  parameter: string | string[]
) {
  if (Array.isArray(parameter)) {
    return parameter[0] as T
  }

  return parameter as T
}

/**
 * Parses optional page request parameter.
 */
export function parseOptionalParameter<T = string>(
  parameter?: string | string[] | null
) {
  if (typeof parameter === 'undefined' || parameter === null) {
    return
  }

  if (Array.isArray(parameter) && parameter.length === 0) {
    return
  }

  return parseRequiredParameter<T>(parameter)
}

/**
 * Parses required page request parameter array.
 */
export function parseRequiredParameters<T = string>(
  parameter: string | string[]
) {
  if (Array.isArray(parameter)) {
    return parameter as T[]
  }

  return [parameter] as T[]
}

/**
 * Parses optional page request parameter array.
 */
export function parseOptionalParameters<T = string>(
  parameter?: string | string[] | null
) {
  if (typeof parameter === 'undefined' || parameter === null) {
    return
  }

  if (Array.isArray(parameter) && parameter.length === 0) {
    return
  }

  return parseRequiredParameters<T>(parameter)
}

/**
 * Validates request method.
 */
export const validateRequestMethod = (
  req: NextApiRequest,
  whitelist?: string[]
) => {
  const requestMethod = req.method

  if (typeof requestMethod === 'undefined') {
    throw new RequestMethodValidationError(`Invalid request method.`)
  }

  if (requestMethod === 'OPTIONS') {
    throw new RequestMethodOptionsError()
  }

  if (typeof whitelist !== 'undefined' && !whitelist.includes(requestMethod)) {
    throw new RequestMethodValidationError(
      `Request method not allowed. Must be one of ${whitelist.join(', ')}.`
    )
  }
}

/**
 * Handles request errors.
 */
export const handleRequestError = (
  res: NextApiResponse,
  error: unknown,
  log: (message?: unknown) => void = console.log,
  defaultStatus?: number,
  defaultBody?: unknown
) => {
  if (error instanceof RequestMethodOptionsError) {
    // Use status code 200 to allow OPTIONS requests
    res.status(200).json({})
    return
  }

  if (error instanceof RequestMethodValidationError) {
    log(error)
    res.status(405).json({
      error: `${error}`,
    })
    return
  }

  if (error instanceof RequestUnprocessableContentError) {
    log(error)
    res.status(422).json({
      error: `${error}`,
    })
    return
  }

  if (error instanceof ParseError) {
    log(error.parseResults)
    // Use status code 200 to allow requester to process this error using parse results instead of try/catch
    res.status(200).json(error.parseResults)
    return
  }

  if (error instanceof RequestStopError) {
    log(error.message)
    // Use status code 200 since this error is intended to be used only to stop a request
    res.status(200).json({
      error: error.message,
    })
    return
  }

  if (error instanceof ProductValidationError) {
    log(error.message)
    res.status(400).json({
      error: error.message,
    })
    return
  }

  log(error)
  res.status(defaultStatus ?? 500).json(
    defaultBody ?? {
      error: `${error}`,
    }
  )
}
