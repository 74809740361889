import { gql } from '@apollo/client'

import { mailingAddressFragment, userErrorFragment } from '../objects/common'
import { customerUserErrorFragment } from '../objects/customers'

export const createCustomerMutation = gql`
  ${customerUserErrorFragment}

  mutation createCustomer($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
`

export const createCustomerAccessTokenMutation = gql`
  ${customerUserErrorFragment}

  mutation createCustomerAccessToken($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
`

export const deleteCustomerAccessTokenMutation = gql`
  ${userErrorFragment}

  mutation deleteCustomerAccessToken($token: String!) {
    customerAccessTokenDelete(customerAccessToken: $token) {
      deletedAccessToken
      deletedCustomerAccessTokenId
      userErrors {
        ...UserErrorFragment
      }
    }
  }
`

export const recoverCustomerMutation = gql`
  ${customerUserErrorFragment}

  mutation recoverCustomer($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
`

export const updateCustomerDefaultAddressMutation = gql`
  ${customerUserErrorFragment}

  mutation updateCustomerDefaultAddress($token: String!, $addressId: ID!) {
    customerDefaultAddressUpdate(
      customerAccessToken: $token
      addressId: $addressId
    ) {
      customer {
        id
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
`

export const createCustomerAddressMutation = gql`
  ${customerUserErrorFragment}
  ${mailingAddressFragment}

  mutation createCustomerAddress(
    $token: String!
    $address: MailingAddressInput!
  ) {
    customerAddressCreate(customerAccessToken: $token, address: $address) {
      customerAddress {
        ...MailingAddressFragment
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
`

export const updateCustomerAddressMutation = gql`
  ${customerUserErrorFragment}
  ${mailingAddressFragment}

  mutation updateCustomerAddress(
    $token: String!
    $addressId: ID!
    $address: MailingAddressInput!
  ) {
    customerAddressUpdate(
      customerAccessToken: $token
      id: $addressId
      address: $address
    ) {
      customerAddress {
        ...MailingAddressFragment
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
`

export const deleteCustomerAddressMutation = gql`
  ${customerUserErrorFragment}

  mutation deleteCustomerAddress($token: String!, $addressId: ID!) {
    customerAddressDelete(customerAccessToken: $token, id: $addressId) {
      deletedCustomerAddressId
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
`
