import { useCallback, useContext, useMemo } from 'react'

import {
  type SanityProductFragment,
  type SanityProductOption,
  type SanityProductVariantFragment,
  type SanityProductVariantOption,
} from '@data/sanity/queries/types/product'
import { type DropdownOption, hasObject } from '@lib/helpers'
import { usePrevious } from '@lib/hooks'
import { useUrlParameters } from '@lib/parameters'
import { parseOptionalParameter } from '@lib/request'
import { SiteContext } from '@lib/site-context'
import { getDefaultOption } from './option'

interface ProductOptionInput {
  option: SanityProductOption
  dropdownOptions: DropdownOption[]
}

export const productVariantLowStockAmount = 5

/**
 * Gets a product variant by the default option.
 */
export const getVariantByDefaultOption = (
  variants: SanityProductVariantFragment[],
  defaultOption?: SanityProductVariantOption
) => {
  if (!defaultOption) {
    return null
  }

  const variant = variants.find(({ options }) =>
    hasObject(options, defaultOption)
  )

  return variant ?? null
}

/**
 * Active product variant hook.
 */
export const useActiveVariant = (product?: SanityProductFragment) => {
  const { isRouteChanging } = useContext(SiteContext)

  const defaultVariantId = useMemo(() => {
    if (!product) {
      return null
    }

    const defaultOption = getDefaultOption(
      product.options,
      product.optionSettings ?? []
    )
    const firstVariant = product.variants?.[0] ?? null
    const defaultVariant =
      getVariantByDefaultOption(product.variants ?? [], defaultOption) ??
      firstVariant
    return defaultVariant?.variantID ?? null
  }, [product])

  const [currentParameters, setCurrentParameters] = useUrlParameters([
    {
      name: 'variant',
      value: defaultVariantId ? `${defaultVariantId}` : null,
    },
  ])

  // Manage URL parameters
  const previousParameters = usePrevious(currentParameters)
  const activeParameters = useMemo(() => {
    return isRouteChanging && previousParameters
      ? previousParameters
      : currentParameters
  }, [currentParameters, previousParameters, isRouteChanging])

  // Find active variant
  const variantIds = useMemo(() => {
    return product?.variants?.map((variant) => variant.variantID) ?? []
  }, [product?.variants])

  const activeVariantId = useMemo(() => {
    const parameterVariant = activeParameters.find(
      (activeParameter) => activeParameter.name === 'variant'
    )
    const parameterVariantValue = parseOptionalParameter<string>(
      parameterVariant?.value
    )
    const parameterVariantId = parameterVariantValue
      ? Number(parameterVariantValue)
      : null

    return variantIds.some((id) => id == parameterVariantId)
      ? parameterVariantId
      : defaultVariantId
  }, [activeParameters, defaultVariantId, variantIds])

  const activeVariant = useMemo(() => {
    return product?.variants?.find(
      (variant) => variant.variantID === activeVariantId
    )
  }, [product?.variants, activeVariantId])

  // Handle variant change
  const updateProductPageUrl = useCallback(
    (variantId: number) => {
      const isValidVariant = variantIds.some((id) => id === variantId)

      setCurrentParameters([
        ...activeParameters.filter(
          (activeParameter) => activeParameter.name !== 'variant'
        ),
        {
          name: 'variant',
          value: isValidVariant ? `${variantId}` : `${defaultVariantId}`,
        },
      ])
    },
    [activeParameters, defaultVariantId, setCurrentParameters, variantIds]
  )

  return [activeVariant, updateProductPageUrl] as const
}

/**
 * Get variant options with new value for the given option name.
 */
export const getUpdatedVariantOptions = (
  variantOptions: SanityProductVariantOption[],
  optionName: string,
  newOptionValue: string
) => {
  return variantOptions.map((variantOption) => {
    if (variantOption.name !== optionName) {
      return variantOption
    }

    const newVariantOption: SanityProductVariantOption = {
      name: variantOption.name,
      value: newOptionValue,
      position: variantOption.position,
    }

    return newVariantOption
  })
}

/**
 * Finds variant from active variant options and new option value.
 * Optionally uses only options with a lower position than the given maximum.
 */
export const getVariantFromOptions = (
  variants: SanityProductVariantFragment[],
  variantOptions: SanityProductVariantOption[],
  optionName: string,
  newOptionValue: string,
  maximumPosition?: number
) => {
  let newVariantOptions = getUpdatedVariantOptions(
    variantOptions,
    optionName,
    newOptionValue
  )

  if (typeof maximumPosition === 'undefined') {
    // Find variant that matches all new options
    return variants.find((variant) =>
      variant.options.every((variantOption) =>
        hasObject(newVariantOptions, variantOption)
      )
    )
  }

  newVariantOptions = newVariantOptions.filter(
    (newVariantOption) => newVariantOption.position <= maximumPosition
  )

  // Find variant that matches all new options (the other way around)
  return variants.find((variant) =>
    newVariantOptions.every((newVariantOption) =>
      hasObject(variant.options, newVariantOption)
    )
  )
}

/**
 * Gets product option input settings from product and variant.
 */
export const getProductOptionInputs = (
  product: SanityProductFragment,
  activeVariant: SanityProductVariantFragment
) => {
  const previousActiveVariantOptions: SanityProductVariantOption[] = []

  return product.options.map((option) => {
    const optionValues = option.values.filter((optionValue) => {
      if (!activeVariant) {
        return true
      }

      // Find variants which have options that match with active variant's previous options
      // E.g., if active variant has options "Size"="A4" and "Quantity"="100 pcs",
      // in "Quantity" input show only those values that have matching variants for "Size"="A4".
      const hasMatchingVariant = !!product.variants
        ?.filter(
          (variant) =>
            previousActiveVariantOptions.length === 0 ||
            previousActiveVariantOptions.every((activeVariantOption) =>
              variant.options.some(
                (variantOption) =>
                  variantOption.name === activeVariantOption.name &&
                  variantOption.value === activeVariantOption.value &&
                  variantOption.position === activeVariantOption.position
              )
            )
        )
        ?.some((variant) =>
          variant.options.some(
            (variantOption) =>
              variantOption.name === option.name &&
              variantOption.position === option.position &&
              variantOption.value === optionValue
          )
        )

      return hasMatchingVariant
    })

    // Add active variant option to list for filtering variants
    if (activeVariant) {
      const activeVariantOption = activeVariant.options.find(
        (activeVariantOption) =>
          activeVariantOption.name === option.name &&
          activeVariantOption.position === option.position
      )

      if (activeVariantOption) {
        previousActiveVariantOptions.push(activeVariantOption)
      }
    }

    const dropdownOptions = optionValues.map((value) => {
      const dropdownOption: DropdownOption = {
        title: value,
        value,
      }

      return dropdownOption
    })

    const input: ProductOptionInput = {
      option,
      dropdownOptions,
    }

    return input
  })
}

/**
 * Gets product's default variant ID.
 */
export const getDefaultVariantId = (product: SanityProductFragment) => {
  if (!product) {
    return
  }

  const defaultOption = getDefaultOption(
    product.options,
    product.optionSettings ?? []
  )
  const firstVariant = product.variants?.[0]
  const defaultVariant = getVariantByDefaultOption(
    product.variants ?? [],
    defaultOption
  )
  const variant = defaultVariant ?? firstVariant

  return variant?.variantID
}

/**
 * Gets product's active variant.
 */
export const getActiveVariant = (
  product: SanityProductFragment,
  selectedVariantId?: number,
  defaultVariantId?: number
) => {
  const variantIds =
    product?.variants?.map((variant) => variant.variantID) ?? []
  const isSelectedVariantValid = variantIds.some(
    (id) => id == selectedVariantId
  )
  const activeVariantId = isSelectedVariantValid
    ? selectedVariantId
    : defaultVariantId

  return product?.variants?.find(
    (variant) => variant.variantID === activeVariantId
  )
}
