import { updateCustomerMutation } from '@data/shopify/admin/mutations/customers'
import {
  type CustomerInput,
  type UpdateCustomerMutation,
  type UpdateCustomerMutationVariables,
} from '@data/shopify/admin/types'
import {
  getCustomerAddressesQuery,
  getCustomerOrdersQuery,
} from '@data/shopify/storefront/queries/customers'
import {
  createCustomerAccessTokenMutation,
  createCustomerMutation,
  deleteCustomerAccessTokenMutation,
  recoverCustomerMutation,
} from '@data/shopify/storefront/mutations/customers'
import {
  type CreateCustomerAccessTokenMutation,
  type CreateCustomerAccessTokenMutationVariables,
  type DeleteCustomerAccessTokenMutation,
  type DeleteCustomerAccessTokenMutationVariables,
  type CustomerCreateInput,
  type CreateCustomerMutation,
  type CreateCustomerMutationVariables,
  type RecoverCustomerMutation,
  type RecoverCustomerMutationVariables,
  type GetCustomerOrdersQuery,
  type GetCustomerOrdersQueryVariables,
  type GetCustomerAddressesQuery,
  type GetCustomerAddressesQueryVariables,
} from '@data/shopify/storefront/types'
import { type Locale } from '@lib/language'
import { ParseError } from '@lib/request'
import {
  type ShopifyClient,
  mutateShopifyAdmin,
  mutateShopifyStorefront,
  queryShopifyStorefront,
} from './client'
import { parseUserErrors } from './helpers'

/**
 * Gets customer with addresses from Shopify.
 */
export const getShopifyCustomerWithAddresses = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  token: string
) => {
  const getCustomerAddressesResult = await queryShopifyStorefront<
    GetCustomerAddressesQuery,
    GetCustomerAddressesQueryVariables
  >(locale, shopifyStorefrontGraphQlClient, getCustomerAddressesQuery, {
    token,
  })

  const customer = getCustomerAddressesResult.data?.customer ?? undefined

  return customer
}

/**
 * Gets customer with orders from Shopify.
 */
export const getShopifyCustomerWithOrders = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  token: string,
  after: string | null,
  limit = 10
) => {
  const getCustomerOrdersResult = await queryShopifyStorefront<
    GetCustomerOrdersQuery,
    GetCustomerOrdersQueryVariables
  >(locale, shopifyStorefrontGraphQlClient, getCustomerOrdersQuery, {
    token,
    limit,
    after,
  })

  const customer = getCustomerOrdersResult.data.customer ?? undefined

  return customer
}

/**
 * Creates a new Shopify customer.
 */
export const createShopifyCustomer = async (
  shopifyAdminGraphQlClient: ShopifyClient,
  input: CustomerCreateInput
) => {
  const createCustomerResult = await mutateShopifyAdmin<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >(shopifyAdminGraphQlClient, createCustomerMutation, {
    input,
  })

  const createCustomerParseResults = parseUserErrors(
    createCustomerResult.data?.customerCreate?.customerUserErrors
  )

  if (createCustomerParseResults.errorCount > 0) {
    throw new ParseError(createCustomerParseResults)
  }

  const customer =
    createCustomerResult.data?.customerCreate?.customer ?? undefined

  return customer
}

/**
 * Updates a new Shopify customer.
 */
export const updateShopifyCustomer = async (
  shopifyAdminGraphQlClient: ShopifyClient,
  input: CustomerInput
) => {
  const updateCustomerResult = await mutateShopifyAdmin<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >(shopifyAdminGraphQlClient, updateCustomerMutation, {
    input,
  })

  const updateCustomerParseResults = parseUserErrors(
    updateCustomerResult.data?.customerUpdate?.userErrors
  )

  if (updateCustomerParseResults.errorCount > 0) {
    throw new ParseError(updateCustomerParseResults)
  }

  const customer =
    updateCustomerResult.data?.customerUpdate?.customer ?? undefined

  return customer
}

/**
 * Creates a new user access token from credentials.
 */
export const createShopifyCustomerAccessToken = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  email: string,
  password: string
) => {
  const createCustomerAccessTokenResult = await mutateShopifyStorefront<
    CreateCustomerAccessTokenMutation,
    CreateCustomerAccessTokenMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, createCustomerAccessTokenMutation, {
    input: {
      email,
      password,
    },
  })

  const updateCustomerParseResults = parseUserErrors(
    createCustomerAccessTokenResult.data?.customerAccessTokenCreate
      ?.customerUserErrors
  )

  if (updateCustomerParseResults.errorCount > 0) {
    throw new ParseError(updateCustomerParseResults)
  }

  const customerAccessToken =
    createCustomerAccessTokenResult.data?.customerAccessTokenCreate
      ?.customerAccessToken ?? undefined

  return customerAccessToken
}

/**
 * Deletes Shopify customer access token.
 */
export const deleteShopifyCustomerAccessToken = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  token: string
) => {
  await mutateShopifyStorefront<
    DeleteCustomerAccessTokenMutation,
    DeleteCustomerAccessTokenMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, deleteCustomerAccessTokenMutation, {
    token,
  })
}

/**
 * Sends password recovery email to Shopify customer.
 */
export const recoverShopifyCustomerPassword = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  email: string
) => {
  const recoverCustomerResult = await mutateShopifyStorefront<
    RecoverCustomerMutation,
    RecoverCustomerMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, recoverCustomerMutation, {
    email,
  })

  const recoverCustomerParseResults = parseUserErrors(
    recoverCustomerResult.data?.customerRecover?.customerUserErrors
  )

  if (recoverCustomerParseResults.errorCount > 0) {
    throw new ParseError(recoverCustomerParseResults)
  }
}
