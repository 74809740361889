import {
  type SanityDiscount,
  type SanityQuantityDiscount,
} from '@data/sanity/queries/types/discount'
import { type VariantLineItem } from '@lib/cart/types'
import { type DiscountItem, type QuantityDiscount } from './types'

/**
 * Gets quantity discounts from all discounts.
 */
export const getQuantityDiscounts = (discounts: SanityDiscount[]) => {
  const quantityDiscounts = discounts.filter(
    (discount) => discount.type === 'quantity'
  ) as SanityQuantityDiscount[]

  return quantityDiscounts.map((quantityDiscount) => {
    const newQuantityDiscount: QuantityDiscount = {
      id: quantityDiscount._id,
      title: quantityDiscount.title,
      discountValuePercent: quantityDiscount.discountValuePercent,
      minimumQuantity: quantityDiscount.minimumQuantity,
      maximumQuantity: quantityDiscount.maximumQuantity,
    }

    return newQuantityDiscount
  })
}

/**
 * Finds active quantity discount by quantity.
 */
const getActiveQuantityDiscount = (
  quantityDiscounts: QuantityDiscount[],
  quantity: number
) => {
  const filteredQuantityDiscounts = quantityDiscounts
    .filter(
      (quantityDiscount) =>
        !quantityDiscount.minimumQuantity ||
        quantity >= quantityDiscount.minimumQuantity
    )
    .filter(
      (quantityDiscount) =>
        !quantityDiscount.maximumQuantity ||
        quantity <= quantityDiscount.maximumQuantity
    )

  // Get the discount with the highest discount percent
  return filteredQuantityDiscounts.reduce<QuantityDiscount | null>(
    (previousDiscount, discount) => {
      if (
        typeof discount?.discountValuePercent !== 'undefined' &&
        (typeof previousDiscount?.discountValuePercent === 'undefined' ||
          discount.discountValuePercent > previousDiscount.discountValuePercent)
      ) {
        return discount
      }

      return previousDiscount
    },
    null
  )
}

/**
 * Gets quantity discount items for variant line items.
 */
export const getQuantityDiscountItems = (
  variantLineItems: VariantLineItem[],
  quantityDiscounts: QuantityDiscount[]
) => {
  const totalQuantity = variantLineItems.reduce(
    (total, variantLineItem) => total + variantLineItem.quantity,
    0
  )
  const activeQuantityDiscount = getActiveQuantityDiscount(
    quantityDiscounts,
    totalQuantity
  )

  if (!activeQuantityDiscount) {
    return []
  }

  const totalPrice = variantLineItems.reduce(
    (total, variantLineItem) =>
      total + variantLineItem.price * variantLineItem.quantity,
    0
  )
  const activeQuantityDiscountRate =
    typeof activeQuantityDiscount.discountValuePercent !== 'undefined'
      ? activeQuantityDiscount.discountValuePercent / 100
      : 0

  const quantityDiscountItem: DiscountItem = {
    id: activeQuantityDiscount.id,
    type: 'quantity',
    title: activeQuantityDiscount.title,
    amount: totalPrice * activeQuantityDiscountRate,
    quantity: 1,
    discountValuePercent: activeQuantityDiscount.discountValuePercent,
  }

  return [quantityDiscountItem]
}
