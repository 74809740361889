import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { compareNumbers } from '@lib/helpers'

export const productLowStockAmount = 10

export const colorOptionNames: string[] = ['Color']

export const sizeOptionNames: string[] = ['Size', 'Størrelse']

/**
 * Converts Sanity product or variant ID string into Shopify ID number.
 */
export const sanityProductIdToShopifyId = (sanityProductId: string) => {
  const shopifyProductId = sanityProductId.split('-')?.[1]

  if (!shopifyProductId) {
    return null
  }

  return Number(shopifyProductId)
}

/**
 * Sorts products in given order of product IDs.
 */
export const getSortedProducts = (
  products: SanityProductFragment[],
  productIds: string[]
) => {
  // Map product IDs to product indices in the sorted ID array
  const productIdIndexMap: Record<string, number> = {}

  productIds.forEach((productId, index) => {
    productIdIndexMap[productId] = index
  })

  return products.sort((product1, product2) => {
    const index1 = productIdIndexMap[product1._id]
    const index2 = productIdIndexMap[product2._id]

    if (typeof index1 === 'undefined' || typeof index2 === 'undefined') {
      return 0
    }

    return compareNumbers(index1, index2)
  })
}
