import { CountryCode } from '@data/shopify/admin/types'
import { type Locale } from './language'

export const defaultCountryCodes: Record<Locale, CountryCode> = {
  da: CountryCode.Dk,
}

type CountryNames = Record<CountryCode, string>

export const englishCountryNames: CountryNames = {
  AC: 'Ascension Island',
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AN: 'Netherlands Antilles',
  AO: 'Angola',
  AR: 'Argentina',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Åland Islands',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'St. Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BQ: 'Caribbean Netherlands',
  BR: 'Brazil',
  BS: 'The Bahamas',
  BT: 'Bhutan',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos (Keeling) Islands',
  CD: 'Democratic Republic of the Congo',
  CF: 'Central African Republic',
  CG: 'Congo',
  CH: 'Switzerland',
  CI: `Côte d'Ivoire`,
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cape Verde',
  CW: 'Curaçao',
  CX: 'Christmas Island',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands',
  FO: 'Faeroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'The Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GS: 'South Georgia and the South Sandwich Islands',
  GT: 'Guatemala',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'Heard Island and McDonald Islands',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IO: 'British Indian Ocean Territory',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Iceland',
  IT: 'Italy',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts and Nevis',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: 'Laos',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MF: 'St. Martin',
  MG: 'Madagascar',
  MK: 'Former Yugoslav Republic of Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macau',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  PN: 'Pitcairn Islands',
  PS: 'Palestinian Territories',
  PT: 'Portugal',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russia',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SH: 'Saint Helena',
  SI: 'Slovenia',
  SJ: 'Svalbard and Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'South Sudan',
  ST: 'São Tomé and Príncipe',
  SV: 'El Salvador',
  SX: 'Sint Maarten',
  SY: 'Syria',
  SZ: 'Swaziland',
  TA: 'Tristan da Cunha',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'East Timor',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'United States Minor Outlying Islands',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatican City',
  VC: 'Saint Vincent and the Grenadines',
  VE: 'Venezuela',
  VG: 'British Virgin Islands',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis and Futuna',
  WS: 'Samoa',
  XK: 'Kosovo',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  ZZ: 'Unknown Region',
}

export const countryNames: Record<Locale, CountryNames> = {
  da: {
    AC: 'Ascension Island',
    AD: 'Andorra',
    AE: 'De Forenede Arabiske Emirater',
    AF: 'Afghanistan',
    AG: 'Antigua og Barbuda',
    AI: 'Anguilla',
    AL: 'Albanien',
    AM: 'Armenien',
    AN: 'De Nederlandske Antiller',
    AO: 'Angola',
    AR: 'Argentina',
    AT: 'Østrig',
    AU: 'Australien',
    AW: 'Aruba',
    AX: 'Ålandsøerne',
    AZ: 'Aserbajdsjan',
    BA: 'Bosnien-Hercegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgien',
    BF: 'Burkina Faso',
    BG: 'Bulgarien',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'St. Barthélemy',
    BM: 'Bermuda',
    BN: 'Brunei',
    BO: 'Bolivia',
    BQ: 'Caribbean Netherlands',
    BR: 'Brasilien',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BV: 'Bouvetø',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Cocosøerne (Keelingøerne)',
    CD: 'Demokratiske Republik Congo',
    CF: 'Den Centralafrikanske Republik',
    CG: 'Congo',
    CH: 'Schweiz',
    CI: `Côte d'Ivoire`,
    CK: 'Cookøerne',
    CL: 'Chile',
    CM: 'Cameroun',
    CN: 'Kina',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Kap Verde',
    CW: 'Curaçao',
    CX: 'Juleøen',
    CY: 'Cypern',
    CZ: 'Tjekkiet',
    DE: 'Tyskland',
    DJ: 'Djibouti',
    DK: 'Danmark',
    DM: 'Dominica',
    DO: 'Den Dominikanske Republik',
    DZ: 'Algeriet',
    EC: 'Ecuador',
    EE: 'Estland',
    EG: 'Egypten',
    EH: 'Vestsahara',
    ER: 'Eritrea',
    ES: 'Spanien',
    ET: 'Etiopien',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falklandsøerne',
    FO: 'Færøerne (Føroyar)',
    FR: 'Frankrig',
    GA: 'Gabon',
    GB: 'Det Forenede Kongerige',
    GD: 'Grenada',
    GE: 'Georgien',
    GF: 'Fransk Guyana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Grønland (Kalaallit Nunaat)',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Ækvatorialguinea',
    GR: 'Grækenland',
    GS: 'South Georgia og De Sydlige Sandwichøer',
    GT: 'Guatemala',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hongkong',
    HM: 'Heard- og McDonaldøerne',
    HN: 'Honduras',
    HR: 'Kroatien',
    HT: 'Haiti',
    HU: 'Ungarn',
    ID: 'Indonesien',
    IE: 'Irland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'Indien',
    IO: 'Det Britiske Territorium i Det Indiske Ocean',
    IQ: 'Irak',
    IR: 'Iran',
    IS: 'Island',
    IT: 'Italien',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kirgisistan',
    KH: 'Cambodja',
    KI: 'Kiribati',
    KM: 'Comorerne',
    KN: 'Saint Kitts og Nevis',
    KP: 'Nordkorea',
    KR: 'Sydkorea',
    KW: 'Kuwait',
    KY: 'Caymanøerne',
    KZ: 'Kasakhstan',
    LA: 'Laos',
    LB: 'Libanon',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Litauen',
    LU: 'Luxembourg',
    LV: 'Letland',
    LY: 'Libyen',
    MA: 'Marokko',
    MC: 'Monaco',
    MD: 'Moldova',
    ME: 'Montenegro',
    MF: 'St. Martin',
    MG: 'Madagaskar',
    MK: 'Den Tidligere Jugoslaviske Republik Makedonien',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongoliet',
    MO: 'Macao',
    MQ: 'Martinique',
    MR: 'Mauretanien',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldiverne',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'Ny Kaledonien',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Nederlandene',
    NO: 'Norge',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'Fransk Polynesien',
    PG: 'Papua Ny Guinea',
    PH: 'Filippinerne',
    PK: 'Pakistan',
    PL: 'Polen',
    PM: 'Saint Pierre og Miquelon',
    PN: 'Pitcairn',
    PS: 'Palæstinensiske territorier',
    PT: 'Portugal',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Rumænien',
    RS: 'Serbien',
    RU: 'Rusland',
    RW: 'Rwanda',
    SA: 'Saudi-Arabien',
    SB: 'Salomonøerne',
    SC: 'Seychellerne',
    SD: 'Sudan',
    SE: 'Sverige',
    SG: 'Singapore',
    SH: 'Saint Helena',
    SI: 'Slovenien',
    SJ: 'Svalbard og Jan Mayen',
    SK: 'Slovakiet',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Surinam',
    SS: 'Sydsudan',
    ST: 'São Tomé og Príncipe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Syrien',
    SZ: 'Swaziland',
    TA: 'Tristan da Cunha',
    TC: 'Turks- og Caicosøerne',
    TD: 'Tchad',
    TF: 'De Franske Besiddelser i Det Sydlige Indiske Ocean',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tadsjikistan',
    TK: 'Tokelau',
    TL: 'Østtimor',
    TM: 'Turkmenistan',
    TN: 'Tunesien',
    TO: 'Tonga',
    TR: 'Tyrkiet',
    TT: 'Trinidad og Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UA: 'Ukraine',
    UG: 'Uganda',
    UM: 'De Mindre Amerikanske Oversøiske Øer',
    US: 'USA',
    UY: 'Uruguay',
    UZ: 'Usbekistan',
    VA: 'Vatikanstaten',
    VC: 'Saint Vincent og Grenadinerne',
    VE: 'Venezuela',
    VG: 'De Britiske Jomfruøer',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis og Futunaøerne',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'Sydafrika',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    ZZ: 'Ukendt region',
  },
}
