import {
  type GetServerSidePropsContext,
  type GetStaticPropsContext,
} from 'next'
import { type ParsedUrlQuery } from 'querystring'

import { type Locale, defaultLocale } from './language'

export type UrlParameters = Record<string, string | null | undefined>

export type Slugs = string | string[] | null

export interface PageUrlOptions {
  newLocale?: Locale
  locale?: Locale
  parameters?: Record<string, string>
}

export type PageType =
  | 'accountAddressPage'
  | 'accountPage'
  | 'blogAuthor'
  | 'blogCategory'
  | 'blogPage'
  | 'blogPost'
  | 'cartPage'
  | 'collection'
  | 'errorPage'
  | 'homePage'
  | 'loginPage'
  | 'logoutPage'
  | 'orderSuccessPage'
  | 'page'
  | 'passwordRecoveryPage'
  | 'product'
  | 'productCategory'
  | 'shopPage'
  | 'signupPage'
  | 'signupSuccessPage'

export type SitemapPageType = Exclude<PageType, 'errorPage'>

export interface CustomStaticPropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetStaticPropsContext<Q> {
  locale: Locale
  locales: Locale[]
}

export interface CustomErrorStaticPropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetStaticPropsContext<Q> {
  locale?: Locale
  locales: Locale[]
}

export interface CustomServerSidePropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetServerSidePropsContext<Q> {
  locale: Locale
}

export interface StaticPathWithSlug {
  params: {
    slug: string
  }
  locale: Locale
}

export interface StaticPathWithSlugs {
  params: {
    slugs: string[]
  }
  locale: Locale
}

interface GetUrlSegmentsOptions {
  pageType: PageType
  slugs?: Slugs
}

type GetPageUrlOptions = GetUrlSegmentsOptions & {
  locale: Locale
  parameters?: UrlParameters
}

type GetLinkPageUrlOptions = GetUrlSegmentsOptions &
  Pick<GetPageUrlOptions, 'parameters'>

const collectionPageTypes: PageType[] = ['collection', 'shopPage']

const productCategoryPageTypes: PageType[] = ['productCategory']

export const pageRoutes: Record<PageType, string[]> = {
  accountAddressPage: ['account', 'addresses'],
  accountPage: ['account'],
  blogAuthor: ['blog', 'author', '[author_slug]'],
  blogCategory: ['blog', 'category', '[category_slug]'],
  blogPage: ['blog'],
  blogPost: ['blog', '[post_slug]'],
  cartPage: ['cart'],
  collection: ['shop', '[collection_slug]'],
  errorPage: ['page-that-does-not-exist-EqchyqRX3HXNjTvx'],
  homePage: [],
  loginPage: ['login'],
  logoutPage: ['api', 'auth', 'logout'],
  orderSuccessPage: ['order-success'],
  page: ['[page_slug]'],
  passwordRecoveryPage: ['password-recovery'],
  product: ['products', '[product_slug]'],
  productCategory: ['shop', 'category', '[category_slug]'],
  shopPage: ['shop'],
  signupPage: ['signup'],
  signupSuccessPage: ['signup-success'],
}

/**
 * Determines if page type has product collection.
 */
export const isCollectionPageType = (pageType: PageType) =>
  collectionPageTypes.includes(pageType)

/**
 * Determines if page type is product category.
 */
export const isProductCategoryPageType = (pageType: PageType) =>
  productCategoryPageTypes.includes(pageType)

/**
 * Gets URL segments from page type and slugs.
 */
const getUrlSegments = (options: GetUrlSegmentsOptions) => {
  const urlSlugs =
    options.slugs && Array.isArray(options.slugs)
      ? options.slugs
      : [options.slugs]
  const pageRoute = pageRoutes[options.pageType] ?? []

  let slugIndex = 0
  const routeSegments = pageRoute.map((segment) => {
    // Replace segment placeholder with a slug or an empty string
    if (/\[.*\]/.test(segment)) {
      slugIndex += 1
      return urlSlugs?.[slugIndex - 1] ?? ''
    }

    return segment
  })

  return routeSegments
}

/**
 * Get URL parameter string.
 */
const getUrlParameterString = (parameters?: UrlParameters) => {
  const searchParams = new URLSearchParams()

  Object.entries(parameters ?? {}).forEach((parameter) => {
    if (parameter[0] && parameter[1]) {
      searchParams.set(parameter[0], parameter[1])
    }
  })

  return searchParams.toString()
}

/**
 * Gets relative page URL for Next.js links without locale segment.
 */
export const getLinkPageUrl = (options: GetLinkPageUrlOptions) => {
  const pageUrlSegments = getUrlSegments(options)
  const url = pageUrlSegments.filter(Boolean).join('/')

  const urlParameters = getUrlParameterString(options.parameters)
  const urlWithParameters = [url, urlParameters].filter(Boolean).join('?')

  return `/${urlWithParameters}`
}

/**
 * Gets relative page URL.
 */
export const getPageUrl = (options: GetPageUrlOptions) => {
  const localeSegment =
    options.locale !== defaultLocale ? `${options.locale}` : null
  const pageUrlSegments = getUrlSegments(options)
  const url = [localeSegment, ...pageUrlSegments].filter(Boolean).join('/')

  const urlParameters = getUrlParameterString(options.parameters)
  const urlWithParameters = [url, urlParameters].filter(Boolean).join('?')

  return `/${urlWithParameters}`
}

/**
 * Gets formatted query string.
 */
export const getFormattedQueryString = (queryString?: string) => {
  if (!queryString) {
    return ''
  }

  let formattedQueryString = `${queryString}`

  if (formattedQueryString.startsWith('?')) {
    formattedQueryString = formattedQueryString.slice(1)
  }

  return `?${formattedQueryString}`
}
