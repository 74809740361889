import cx from 'classnames'
import { useContext } from 'react'

import { StringsContext } from '@lib/strings-context'

import ProductPrice from '@blocks/product/product-price'

interface CartSummaryTotalProps {
  price: number
  className?: string
}

const CartSummaryTotal = ({ price, className }: CartSummaryTotalProps) => {
  const strings = useContext(StringsContext)

  return (
    <div
      className={cx(
        'text-sm flex gap-x-5 justify-between font-medium py-3.5',
        className
      )}
    >
      <span>{strings.cartTotal}</span>

      <ProductPrice
        price={price}
        showPrice
        showPriceTaxSuffix
        showCurrency
        className="shrink-0"
      />
    </div>
  )
}

export default CartSummaryTotal
