import { useEffect, useRef } from 'react'

/**
 * Previous value hook.
 */
export function usePrevious<T>(value: T) {
  const previousValue = useRef<T>()

  useEffect(() => {
    previousValue.current = value
  }, [value])

  return previousValue.current
}
