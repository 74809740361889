import {
  publishPublishableMutation,
  publishPublishableToCurrentChannelMutation,
} from '@data/shopify/admin/mutations/store-properties'
import { getPublicationsQuery } from '@data/shopify/admin/queries/apps'
import {
  type GetPublicationsQuery,
  type PublicationInput,
  type PublishPublishableMutation,
  type PublishPublishableMutationVariables,
  type PublishPublishableToCurrentChannelMutation,
  type PublishPublishableToCurrentChannelMutationVariables,
} from '@data/shopify/admin/types'
import {
  getShopAndCartQuery,
  getShopQuery,
} from '@data/shopify/storefront/queries/common'
import {
  type CartFragmentFragment,
  type GetShopAndCartQuery,
  type GetShopAndCartQueryVariables,
  type GetShopQuery,
  type ShopFragmentFragment,
} from '@data/shopify/storefront/types'
import { type Locale } from '@lib/language'
import { getShopifyCartIdStorageKey } from '@lib/local-storage'
import {
  type ShopifyClient,
  mutateShopifyAdmin,
  queryShopifyStorefront,
} from './client'
import { getUserErrorMessage } from './helpers'

type ShopifyShopAndCart = [
  ShopFragmentFragment | undefined,
  CartFragmentFragment | undefined
]

/**
 * Gets Shopify shop information.
 */
const getShopifyShop = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient
) => {
  const getShopResult = await queryShopifyStorefront<GetShopQuery>(
    locale,
    shopifyStorefrontGraphQlClient,
    getShopQuery
  )

  const shop = getShopResult.data?.shop ?? undefined

  return [
    shop as ShopFragmentFragment | undefined,
    undefined,
  ] as ShopifyShopAndCart
}

/**
 * Gets Shopify shop information and cart.
 */
export const getShopifyShopAndCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient
) => {
  // Read cart ID from local storage
  const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
  const cartId = localStorage.getItem(shopifyCartIdStorageKey)

  if (!cartId) {
    return await getShopifyShop(locale, shopifyStorefrontGraphQlClient)
  }

  const getShopAndCartResult = await queryShopifyStorefront<
    GetShopAndCartQuery,
    GetShopAndCartQueryVariables
  >(locale, shopifyStorefrontGraphQlClient, getShopAndCartQuery, {
    cartId,
  })

  const shop = getShopAndCartResult.data?.shop ?? undefined
  const cart = getShopAndCartResult.data?.cart ?? undefined

  return [
    shop as ShopFragmentFragment | undefined,
    cart as CartFragmentFragment | undefined,
  ] as ShopifyShopAndCart
}

/**
 * Gets Shopify publications (sales channels).
 */
export const getShopifyPublications = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient
) => {
  const getPublicationsResult =
    await queryShopifyStorefront<GetPublicationsQuery>(
      locale,
      shopifyStorefrontGraphQlClient,
      getPublicationsQuery
    )

  if (!getPublicationsResult.data) {
    return
  }

  return getPublicationsResult.data.publications.nodes.map((node) => ({
    id: node.id,
    name: node.name,
  }))
}

/**
 * Publishes a publishable item to current sales channel in Shopify.
 */
export const publishShopifyPublishableToCurrentChannel = async (
  shopifyAdminGraphQlClient: ShopifyClient,
  id: string
) => {
  const publishPublishableToCurrentChannelMutationResult =
    await mutateShopifyAdmin<
      PublishPublishableToCurrentChannelMutation,
      PublishPublishableToCurrentChannelMutationVariables
    >(shopifyAdminGraphQlClient, publishPublishableToCurrentChannelMutation, {
      id,
    })
  const result =
    publishPublishableToCurrentChannelMutationResult.data
      ?.publishablePublishToCurrentChannel
  const userErrorMessage = getUserErrorMessage(result?.userErrors)

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  // Validate that publishable was published
  if (!result?.publishable?.publishedOnCurrentPublication) {
    throw new Error('Failed to publish product')
  }
}

/**
 * Publishes a publishable item in Shopify.
 */
export const publishShopifyPublishable = async (
  shopifyAdminGraphQlClient: ShopifyClient,
  id: string,
  input: PublicationInput[]
) => {
  const publishPublishableMutationResult = await mutateShopifyAdmin<
    PublishPublishableMutation,
    PublishPublishableMutationVariables
  >(shopifyAdminGraphQlClient, publishPublishableMutation, {
    id,
    input,
  })
  const result = publishPublishableMutationResult.data?.publishablePublish
  const userErrorMessage = getUserErrorMessage(result?.userErrors)

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  // Validate that publishable was published on all input publications
  input.forEach((inputItem) => {
    const publicationId = inputItem.publicationId

    if (!publicationId) {
      return
    }

    const isPublished = result?.publishable?.resourcePublicationsV2.nodes.some(
      (node) => node.publication.id === publicationId
    )

    if (!isPublished) {
      throw new Error('Failed to publish product')
    }
  })
}
