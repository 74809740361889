import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { ProductContext } from './context'
import {
  type ProductQuickAddContextProps,
  type ProductQuickAddContextProviderProps,
} from './types'

export const ProductQuickAddContext =
  createContext<ProductQuickAddContextProps>({
    productQuickAdd: {
      isOpen: false,
      product: null,
    },
    openProductQuickAdd: () => null,
    closeProductQuickAdd: () => null,
  })

export const ProductQuickAddContextProvider = ({
  children,
}: ProductQuickAddContextProviderProps) => {
  const { setPassepartoutHoleSize } = useContext(ProductContext)

  const [isOpen, setIsOpen] = useState(false)
  const [product, setProduct] = useState<SanityProductFragment | null>(null)

  const openProductQuickAdd = useCallback((product: SanityProductFragment) => {
    setProduct(product)
    setIsOpen(true)
  }, [])

  const closeProductQuickAdd = useCallback(() => {
    setIsOpen(false)

    // Wait for animation to finish
    setTimeout(() => {
      setProduct(null)
    }, 200)
  }, [])

  // Clear mount cut-out size when product is unloaded
  useEffect(() => {
    if (!product) {
      setPassepartoutHoleSize('')
    }
  }, [product, setPassepartoutHoleSize])

  return (
    <ProductQuickAddContext.Provider
      value={{
        productQuickAdd: {
          isOpen,
          product,
        },
        openProductQuickAdd,
        closeProductQuickAdd,
      }}
    >
      {children}
    </ProductQuickAddContext.Provider>
  )
}
