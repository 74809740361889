import cx from 'classnames'
import { type ButtonHTMLAttributes, forwardRef, ReactNode, useId } from 'react'

import { ScreenSizeName, screenSizeNames } from '@lib/theme'

import {
  type SanityButtonColor,
  type SanityButtonIconAlignment,
  type SanityButtonSize,
  type SanityButtonVariant,
} from '@data/sanity/queries/types/link'

import Icon, { IconName } from '@components/icon'

export type ButtonVariant = 'link' | 'filled' | 'outlined'

export type ButtonSize = 'default' | 'xs' | 'small' | 'normal'

export type ButtonColor = 'inherit' | 'default' | 'white' | 'black' | 'gray'

export type ButtonIconAlignment = 'left' | 'right'

type VariantColorClassMap = Record<
  ButtonVariant,
  Record<ButtonColor | 'default', string | string[]>
>

export type SizeClassMap = Record<ButtonSize, string | string[]>

export type ResponsiveSizeClassMap = Record<ScreenSizeName, SizeClassMap>

export type ColorClassMap = Record<ButtonColor, string | string[]>

interface ButtonLabelProps {
  children: ReactNode
  hideUntil?: ScreenSizeName
}

interface ButtonIconProps {
  name: IconName
  alignment?: ButtonIconAlignment | null
  size?: ButtonSize
  className?: string
}

export interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  color?: ButtonColor
  icon?: IconName
  iconAlignment?: ButtonIconAlignment
  isActive?: boolean
  hideLabelUntil?: ScreenSizeName
  className?: string
  iconClassName?: string
}

export const getBaseClasses = (
  variant?: ButtonVariant,
  color?: ButtonColor,
  hasIcon?: boolean
) =>
  cx('inline-flex items-center disabled:opacity-50 transition-all', {
    'justify-center rounded-full whitespace-nowrap font-medium text-center border-[1px] leading-[1.25]':
      variant !== 'link',
    'underline hover:no-underline': variant === 'link' && !hasIcon,
    'font-normal hover:opacity-60': variant === 'link' && hasIcon,
    'hover:opacity-80': variant === 'filled',
  })

export const colorClasses: VariantColorClassMap = {
  filled: {
    white: ['border-white bg-white text-black'],
    black: ['border-black bg-black text-white'],
    gray: ['border-gray-300 bg-gray-300 text-black'],
    default: [
      'bg-btn-filled-bg text-btn-filled-text border-btn-filled-border',
      'hover:bg-btn-filled-bg-hover hover:text-btn-filled-text-hover hover:border-btn-filled-border-hover',
    ],
    inherit: [],
  },
  outlined: {
    white: [
      'bg-transparent border-white text-white',
      'hover:bg-white hover:text-black',
    ],
    black: [
      'bg-transparent border-black text-black',
      'hover:bg-black hover:text-white',
    ],
    gray: [
      'bg-transparent border-gray-300 text-black',
      'hover:border-gray-200',
    ],
    default: [
      'bg-btn-outlined-bg border border-btn-outlined-border text-btn-outlined-text',
      'hover:bg-btn-outlined-bg-hover hover:text-btn-outlined-text-hover hover:border-btn-outlined-border-hover',
    ],
    inherit: ['bg-transparent border-current text-current'],
  },
  link: {
    white: ['text-white'],
    black: ['text-black'],
    gray: ['text-gray-300'],
    default: ['text-current'],
    inherit: ['text-current'],
  },
}

const sizeClasses: SizeClassMap = {
  default: '',
  xs: 'text-sm',
  small: 'text-sm',
  normal: 'text-sm',
}

const spacingClasses: SizeClassMap = {
  default: '',
  xs: 'px-2 py-1.5 gap-x-2',
  small: 'px-5.5 py-2.5 gap-x-2',
  normal: 'px-8 py-4.5 gap-x-2',
}

// TODO: Make `generateResponsiveClasses` work to generate Tailwind classes ahead using them to avoid the madness below.
const responsiveSpacingClasses: ResponsiveSizeClassMap = {
  xs: {
    default: '',
    xs: 'xs:px-2 py-1.5 xs:gap-x-2',
    small: 'xs:px-5.5 xs:py-2.5 xs:gap-x-2',
    normal: 'xs:px-8 xs:py-4.5 xs:gap-x-2',
  },
  sm: {
    default: '',
    xs: 'xs:px-2 py-1.5 xs:gap-x-2',
    small: 'sm:px-5.5 sm:py-2.5 sm:gap-x-2',
    normal: 'sm:px-8 sm:py-4.5 sm:gap-x-2',
  },
  md: {
    default: '',
    xs: 'sm:px-2 py-1.5 sm:gap-x-2',
    small: 'md:px-5.5 md:py-2.5 md:gap-x-2',
    normal: 'md:px-8 md:py-4.5 md:gap-x-2',
  },
  lg: {
    default: '',
    xs: 'lg:px-2 py-1.5 lg:gap-x-2',
    small: 'lg:px-5.5 lg:py-2.5 lg:gap-x-2',
    normal: 'lg:px-8 lg:py-4.5 lg:gap-x-2',
  },
  xl: {
    default: '',
    xs: 'xl:px-2 py-1.5 xl:gap-x-2',
    small: 'xl:px-5.5 xl:py-2.5 xl:gap-x-2',
    normal: 'xl:px-8 xl:py-4.5 xl:gap-x-2',
  },
}

const iconSizeClasses: SizeClassMap = {
  default: '',
  xs: 'text-lg',
  small: 'text-xl',
  normal: 'text-xl',
}

const iconSpacingClasses: SizeClassMap = {
  default: '',
  xs: 'p-1',
  small: 'p-2',
  normal: 'p-3',
}

const activeClasses: VariantColorClassMap = {
  filled: {
    white: 'border-white bg-white text-black',
    black: 'border-black bg-black text-white',
    gray: 'border-gray-300 bg-gray-300 text-black',
    default:
      'bg-btn-filled-bg-hover !text-btn-filled-text-hover border-btn-filled-border-hover',
    inherit: '',
  },
  outlined: {
    white: 'border-white bg-white !text-black',
    black: 'border-black bg-black !text-white',
    gray: '!border-black !bg-black !text-white',
    default:
      'bg-btn-outlined-bg-hover !text-btn-outlined-text-hover border-btn-outlined-border-hover',
    inherit: 'bg-transparent border-current text-current opacity-60',
  },
  link: colorClasses['link'],
}

const labelVisibilityClasses: Record<ScreenSizeName, string> = {
  xs: 'hidden xs:inline',
  sm: 'hidden sm:inline',
  md: 'hidden md:inline',
  lg: 'hidden lg:inline',
  xl: 'hidden xl:inline',
}

export const getButtonVariant = (variant?: SanityButtonVariant) => {
  if (!variant) {
    return
  }

  return variant as ButtonVariant
}

export const getButtonSize = (size?: SanityButtonSize) => {
  if (!size) {
    return 'default' as ButtonSize
  }

  return size as ButtonSize
}

export const getButtonColor = (color?: SanityButtonColor) => {
  if (!color) {
    return
  }

  return color as ButtonColor
}

export const getButtonIconAlignment = (
  iconAlignment?: SanityButtonIconAlignment
) => {
  if (!iconAlignment) {
    return
  }

  return iconAlignment as ButtonIconAlignment
}

interface GetButtonStylesProps extends ButtonProps {
  children?: ReactNode
}

export const getButtonStyles = ({
  variant = 'link',
  color = 'default',
  size = 'normal',
  isActive,
  icon,
  hideLabelUntil,
  children,
}: GetButtonStylesProps) => {
  const hasChildren = Boolean(children)
  const isLabelHidden =
    hideLabelUntil && screenSizeNames.includes(hideLabelUntil)
  const iconOnly = (icon && !hasChildren) || isLabelHidden

  const defaultSpacing = iconOnly
    ? iconSpacingClasses[size]
    : spacingClasses[size]

  const responsiveSpacing = hideLabelUntil
    ? `${iconSpacingClasses[size]} ${responsiveSpacingClasses[hideLabelUntil][size]}`
    : defaultSpacing

  // Using optional chaining operators below because Sanity could contain a deprecated variant, color or size
  return cx(
    getBaseClasses(variant, color, Boolean(icon)),
    colorClasses[variant]?.[color],
    isActive ? activeClasses[variant]?.[color] : '',
    variant !== 'link' && responsiveSpacing,
    sizeClasses[size]
  )
}

export const ButtonLabel = ({ children, hideUntil }: ButtonLabelProps) => (
  <span className={hideUntil ? labelVisibilityClasses[hideUntil] : ''}>
    {children}
  </span>
)

export const ButtonIcon = ({
  name,
  size,
  alignment = 'right',
  className,
}: ButtonIconProps) => {
  const id = useId()

  return (
    <span
      className={cx(
        {
          'order-first': alignment === 'left',
        },
        size ? iconSizeClasses[size] : '',
        className
      )}
    >
      <Icon id={`button-icon-${id}`} name={name} />
    </span>
  )
}

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      children,
      className,
      iconClassName,
      disabled,
      onClick,
      onBeforeInput,
      id,
      style,
      type,
      'aria-label': ariaLabel,
      variant,
      size,
      color,
      icon,
      iconAlignment,
      isActive,
      hideLabelUntil,
    },
    ref
  ) => (
    <button
      type={type}
      id={id}
      ref={ref}
      className={cx(
        'group',
        getButtonStyles({
          variant,
          size,
          color,
          isActive,
          icon,
          hideLabelUntil,
          children,
        }),
        className
      )}
      onClick={onClick}
      disabled={disabled}
      style={style}
      onBeforeInput={onBeforeInput}
      aria-label={ariaLabel}
    >
      {children && (
        <ButtonLabel hideUntil={hideLabelUntil}>{children}</ButtonLabel>
      )}
      {icon && (
        <ButtonIcon
          name={icon}
          alignment={iconAlignment}
          size={size}
          className={iconClassName}
        />
      )}
    </button>
  )
)

Button.displayName = 'Button'

export default Button
