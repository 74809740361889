import axios, { type AxiosResponse } from 'axios'

import { type Locale } from '@lib/language'
import {
  type LoginPayload,
  type LoginResponse,
  type LogoutPayload,
  type LogoutResponse,
  type SignupPayload,
  type SignupResponse,
  type StoredUser,
} from './types'

/**
 * Gets user data by calling an API route.
 */
export const getUser = async (locale: Locale) => {
  const response = await axios.get<StoredUser>('/api/auth/user', {
    headers: {
      'X-Locale': locale,
    },
  })

  return response.data
}

/**
 * Creates a new user session by calling an API route.
 */
export const loginUser = async (locale: Locale, payload: LoginPayload) => {
  const response = await axios.post<
    LoginResponse,
    AxiosResponse<LoginResponse>,
    LoginPayload
  >('/api/auth/login', payload, {
    headers: {
      'Content-Type': 'application/json',
      'X-Locale': locale,
    },
  })

  return response.data
}

/**
 * Deletes user session by calling an API route.
 */
export const logoutUser = async (locale: Locale, payload: LogoutPayload) => {
  const response = await axios.post<
    LogoutResponse,
    AxiosResponse<LogoutResponse>,
    LogoutPayload
  >('/api/auth/logout', payload, {
    headers: {
      'Content-Type': 'application/json',
      'X-Locale': locale,
    },
  })

  return response.data
}

/**
 * Creates a new user by calling an API route.
 */
export const signupUser = async (locale: Locale, payload: SignupPayload) => {
  const response = await axios.post<
    SignupResponse,
    AxiosResponse<SignupResponse>,
    SignupPayload
  >('/api/auth/signup', payload, {
    headers: {
      'Content-Type': 'application/json',
      'X-Locale': locale,
    },
  })

  return response.data
}
