import {
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
  useContext,
  useState,
} from 'react'
import { useForm, Controller } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import { validateVatId } from '@lib/cart/request'
import { type ErrorMessages } from '@lib/request'
import { StringsContext } from '@lib/strings-context'

import Accordion from '@components/accordion'
import InputField from '@components/input-field/with-form-register'
import ValidationIcon from '@components/validation-icon'

interface VatIdFieldProps {
  errorMessages: ErrorMessages
  setErrorMessages: Dispatch<SetStateAction<ErrorMessages>>
  onChange?: (value: string) => void
  className?: string
}

interface VatIdValues {
  vatId: string
}

const VatIdField = ({
  errorMessages,
  setErrorMessages,
  onChange,
  className,
}: VatIdFieldProps) => {
  const strings = useContext(StringsContext)

  const { control } = useForm<VatIdValues>()

  const [isValid, setIsValid] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false)

  const vatIdError = errorMessages.vatId

  const debouncedValidateVatId = debounce(1000, async (vatId: string) => {
    if (!vatId) {
      return
    }

    try {
      const validationResult = await validateVatId(vatId)
      setIsValid(validationResult.isValid)
    } catch {
      setIsValid(false)
    }

    setIsInProgress(false)
  })

  const handleChange = (event: ChangeEvent) => {
    const inputEvent = event as ChangeEvent<HTMLInputElement>

    setIsInProgress(true)
    debouncedValidateVatId(inputEvent.target.value)

    if (vatIdError) {
      setErrorMessages((messages) => ({
        ...messages,
        vatId: '',
      }))
    }

    if (onChange) {
      onChange(inputEvent.target.value)
    }
  }

  return (
    <Accordion
      id="cart-vat-id"
      title={strings.cartVatSectionTitle}
      borderBottom
      hideOverflow
      className={className}
    >
      <Controller
        name="vatId"
        control={control}
        render={({ field: { value, onChange, onBlur, ...vatIdRegister } }) => (
          <>
            <InputField
              type="text"
              formRegister={{
                ...vatIdRegister,
                onBlur: async () => {
                  onBlur()
                },
                onChange: async (event: ChangeEvent) => {
                  onChange(event)
                  handleChange(event)
                },
              }}
              errorMessage={vatIdError}
              placeholder={strings.cartVatIdInputPlaceholder}
            />

            {!vatIdError && value && (
              <ValidationIcon isValid={isValid} isInProgress={isInProgress} />
            )}
          </>
        )}
      />
    </Accordion>
  )
}

export default VatIdField
