import cx from 'classnames'
import { useRouter } from 'next/router'
import {
  type Dispatch,
  type MouseEvent,
  type ReactNode,
  type SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { type SanityCartSettings } from '@data/sanity/queries/types/cart'
import {
  AnalyticsEventName,
  getInitiateCheckoutEventPayload,
} from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { CartContext } from '@lib/cart/context'
import { usePrepareCart } from '@lib/cart/hooks'
import { createShopifyDraftOrder } from '@lib/cart/request'
import { type CartFormValues } from '@lib/cart/types'
import { DiscountContext } from '@lib/discount/context'
import { LanguageContext } from '@lib/language-context'
import { getShopifyCartIdStorageKey } from '@lib/local-storage'
import { type ErrorMessages } from '@lib/request'
import { getLinkPageUrl } from '@lib/routes'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'
import { UserContext } from '@lib/user/context'
import { getIsUserB2bClient } from '@lib/user/helpers'

import Alert from '@components/alert'
import Button from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import SimplePortableText from '@components/simple-portable-text'

interface CartSubmitProps {
  cartSettings: SanityCartSettings
  cartFormValues: CartFormValues
  setErrorMessages: Dispatch<SetStateAction<ErrorMessages>>
  onClick?: () => void
  children: ReactNode
  className?: string
}

const CartSubmit = ({
  cartSettings,
  cartFormValues,
  setErrorMessages,
  onClick,
  children,
  className,
}: CartSubmitProps) => {
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)
  const { cart, isCartUpdating, isCartSubmitting, setIsCartSubmitting } =
    useContext(CartContext)
  const { cartDiscountItems } = useContext(DiscountContext)
  const { locale } = useContext(LanguageContext)
  const { currencyCode, loadShop } = useContext(ShopContext)
  const strings = useContext(StringsContext)
  const { user } = useContext(UserContext)

  const { register, watch } = useForm()
  const router = useRouter()
  const prepareCart = usePrepareCart()

  const [isError, setIsError] = useState(false)

  const acceptTermsRegister = register('acceptTerms')
  const acceptTerms = watch('acceptTerms')

  const isUserB2bClient = useMemo(() => getIsUserB2bClient(user), [user])

  const successRedirect = useCallback(async () => {
    if (isUserB2bClient) {
      // Redirect to order success page
      const url = getLinkPageUrl({
        pageType: 'orderSuccessPage',
      })
      router.push(url, url, {
        locale,
      })
    }
  }, [isUserB2bClient, locale, router])

  const handleClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()

      try {
        if (isUserB2bClient && !cart.hasShippingOptions) {
          return
        }

        setIsError(false)
        setIsCartSubmitting(true)

        const prepareCartResult = await prepareCart(cartFormValues)

        setErrorMessages((currentErrorMessages) => ({
          ...currentErrorMessages,
          vatId: prepareCartResult.errors.vatId,
        }))

        if (Object.entries(prepareCartResult.errors).length > 0) {
          console.log(prepareCartResult.errors)
          setIsCartSubmitting(false)
          return
        }

        if (user && isUserB2bClient) {
          await createShopifyDraftOrder(locale, cart, user)

          // Clear cart
          const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
          localStorage.removeItem(shopifyCartIdStorageKey)

          // Load shop and cart from Shopify
          loadShop()
        }

        // Trigger on click handler
        if (onClick) {
          onClick()
        }

        setIsCartSubmitting(false)

        setTimeout(async () => {
          // Trigger initiate checkout event
          const eventPayload = getInitiateCheckoutEventPayload(
            cart,
            currencyCode,
            cartDiscountItems
          )
          triggerAnalyticsEvent(
            AnalyticsEventName.InitiateCheckout,
            eventPayload
          )
          // Deprecated. Encourage clients to use GTM instead.
          // if (settings?.facebookEvents) {
          //   await triggerInitiateCheckoutFacebookEvent(
          //     locale,
          //     cart,
          //     cartDiscountItems ?? [],
          //     currencyCode
          //   )
          // }

          await successRedirect()
        }, 0)
      } catch (error) {
        console.log(error)

        setIsCartSubmitting(false)
        setIsError(true)
      }
    },
    [
      cart,
      cartDiscountItems,
      cartFormValues,
      currencyCode,
      isUserB2bClient,
      loadShop,
      locale,
      onClick,
      prepareCart,
      setErrorMessages,
      setIsCartSubmitting,
      successRedirect,
      triggerAnalyticsEvent,
      user,
    ]
  )

  const isDisabled =
    !user ||
    !cart.hasShippingOptions ||
    isCartUpdating ||
    isCartSubmitting ||
    (!!cartSettings.terms && !acceptTerms)

  return (
    <div className={cx('space-y-4', className)}>
      {!!cartSettings.terms && (
        <Checkbox formRegister={acceptTermsRegister}>
          <SimplePortableText content={cartSettings.terms} className="rc" />
        </Checkbox>
      )}

      <Button
        variant="filled"
        disabled={isDisabled}
        onClick={handleClick}
        className="w-full"
      >
        {isCartUpdating && <>{strings.buttonUpdating}</>}
        {!isCartUpdating && isCartSubmitting && <>{strings.buttonSubmitting}</>}
        {!isCartUpdating && !isCartSubmitting && <>{children}</>}
      </Button>

      {isError && !!cartSettings.createOrderError && (
        <Alert error>
          <SimplePortableText
            content={cartSettings.createOrderError}
            className="rc rc-alert"
          />
        </Alert>
      )}
      {!!user?.isLoggedIn &&
        !cart.hasShippingOptions &&
        !!cartSettings.shippingOptionsMissing && (
          <Alert error>
            <SimplePortableText
              content={cartSettings.shippingOptionsMissing}
              className="rc rc-alert"
            />
          </Alert>
        )}
    </div>
  )
}

export default CartSubmit
