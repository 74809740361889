import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useState,
} from 'react'

import { type SanitySeo } from '@data/sanity/queries/types/seo'

interface MetadataContextProps {
  metadataOverride: SanitySeo | null
  setMetadataOverride: Dispatch<SetStateAction<SanitySeo | null>>
}

export const MetadataContext = createContext<MetadataContextProps>({
  metadataOverride: null,
  setMetadataOverride: () => null,
})

interface MetadataContextProviderProps {
  children: ReactNode
}

export const MetadataContextProvider = ({
  children,
}: MetadataContextProviderProps) => {
  const [metadataOverride, setMetadataOverride] = useState<SanitySeo | null>(
    null
  )

  return (
    <MetadataContext.Provider
      value={{
        metadataOverride,
        setMetadataOverride,
      }}
    >
      {children}
    </MetadataContext.Provider>
  )
}
