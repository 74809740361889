import cx from 'classnames'
import { useContext } from 'react'

import { StringsContext } from '@lib/strings-context'

import ProductPrice from '@blocks/product/product-price'

interface CartSummaryShippingProps {
  title?: string
  price: number
  className?: string
}

const CartSummaryShipping = ({
  title,
  price,
  className,
}: CartSummaryShippingProps) => {
  const strings = useContext(StringsContext)

  return (
    <div
      className={cx(
        'text-sm flex gap-x-5 justify-between font-medium border-b py-3.5',
        className
      )}
    >
      <div className="inline-flex flex-wrap gap-x-5 items-center">
        {strings.cartShipping}
        <span className="text-gray-700">{title}</span>
      </div>

      <ProductPrice price={price} showPrice showCurrency className="shrink-0" />
    </div>
  )
}

export default CartSummaryShipping
