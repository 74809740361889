import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  type SanityCombinedListingProduct,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { LanguageContext } from '@lib/language-context'
import { useVariantMetadata } from '@lib/metadata'
import { useProductWithInventory } from './inventory'
import { useActiveVariant } from './variant'

interface ProductContextProps {
  product?: SanityCombinedListingProduct
  activeVariant?: SanityProductVariantFragment
  passepartoutHoleSize: string
  onVariantChange: (variantId: number) => void
  setPassepartoutHoleSize: (newPassepartoutHoleSize: string) => void
}

interface ProductContextProviderProps {
  product?: SanityCombinedListingProduct
  children: ReactNode
}

export const ProductContext = createContext<ProductContextProps>({
  passepartoutHoleSize: '',
  onVariantChange: () => {},
  setPassepartoutHoleSize: () => {},
})

export const ProductContextProvider = ({
  product: originalProduct,
  children,
}: ProductContextProviderProps) => {
  const { locale } = useContext(LanguageContext)

  const [product, setProduct] = useState<SanityCombinedListingProduct>()
  const [passepartoutHoleSize, setPassepartoutHoleSize] = useState('')

  // Active product
  const activeProduct = useMemo(
    () => product ?? originalProduct,
    [originalProduct, product]
  )

  // Get active product and variant
  const productWithInventory = useProductWithInventory(locale, activeProduct)
  const [activeVariant, onVariantChange] =
    useActiveVariant(productWithInventory)

  // Set product variant metadata
  useVariantMetadata(activeVariant)

  // Load original product
  useEffect(() => {
    setProduct(originalProduct)
    // Clear mount cut-out size when product changes
    setPassepartoutHoleSize('')
  }, [originalProduct])

  return (
    <ProductContext.Provider
      value={{
        product: productWithInventory,
        activeVariant,
        passepartoutHoleSize,
        onVariantChange,
        setPassepartoutHoleSize,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
