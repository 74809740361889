interface PriceOptions {
  discountRate?: number
  applyDiscount?: boolean
  taxRate?: number
  applyTax?: boolean
  removeTax?: boolean
  hasTrailingZeros?: boolean
  thousandSeparator?: string
  decimalSeparator?: string
  minorUnitScale?: number
  showCurrency?: boolean
}

type PriceWithCurrencyOptions = PriceOptions & {
  showCurrency?: boolean
}

export const defaultThousandSeparator = '.'

export const defaultDecimalSeparator = ','

/**
 * Gets formatted date by date string and locale.
 */
export const getFormattedDate = (
  date: string,
  locale: string,
  formatOptions?: Intl.DateTimeFormatOptions
) => {
  const dateTimeFormat = new Intl.DateTimeFormat(
    locale,
    formatOptions ?? {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
  )

  return dateTimeFormat.format(new Date(date))
}

/**
 * Formats a value by adding thousands separators.
 */
export const addThousandSeparators = (
  value: string,
  thousandSeparator: string
) => {
  if (!thousandSeparator) {
    return value
  }

  return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
}

/**
 * Gets price from value in minor units. Adds tax and trailing zeros, if needed.
 */
export const getPrice = (minorUnits: number, options?: PriceOptions) => {
  const discountRate = options?.discountRate ?? 0
  const applyDiscount = options?.applyDiscount ?? false
  const taxRate = options?.taxRate ?? 0
  const applyTax = options?.applyTax ?? false
  const removeTax = options?.removeTax ?? false
  const hasTrailingZeros = !!options?.hasTrailingZeros
  const thousandSeparator =
    options?.thousandSeparator ?? defaultThousandSeparator
  const decimalSeparator = options?.decimalSeparator ?? defaultDecimalSeparator
  const minorUnitScale = options?.minorUnitScale ?? 2

  // Convert price from minor to major units
  let price = minorUnits / Math.pow(10, minorUnitScale)

  // Apply discount
  if (applyDiscount && discountRate !== 0) {
    price = price * (1 - discountRate)
  }

  // Apply or remove tax
  if (applyTax && removeTax) {
    console.warn('Price tax options conflict')
  }

  if (taxRate < 0) {
    console.warn('Price tax rate is negative')
  }

  if (taxRate > 0) {
    if (applyTax && !removeTax) {
      price = price * (1 + taxRate)
    }

    if (!applyTax && removeTax) {
      price = price / (1 + taxRate)
    }
  }

  if (!hasTrailingZeros && price % 1 === 0) {
    return addThousandSeparators(`${price}`, thousandSeparator)
  }

  // Add trailing zeros
  const priceParts = price.toFixed(minorUnitScale).split('.')
  priceParts[0] = addThousandSeparators(priceParts[0], thousandSeparator)

  return `${priceParts.join(decimalSeparator)}`
}

/**
 * Gets price with currency from minor units. Adds tax and trailing zeros, if needed.
 */
export const getPriceWithCurrency = (
  minorUnits: number,
  currency?: string,
  options?: PriceWithCurrencyOptions
) => {
  const showCurrency = options?.showCurrency ?? false

  const price = getPrice(minorUnits, options)

  if (!showCurrency || !currency) {
    return price
  }

  return `${price} ${currency}`
}

/**
 * Gets price number from value in minor units.
 */
export const getPriceNumber = (minorUnits: number) =>
  Number(
    getPrice(minorUnits, {
      hasTrailingZeros: true,
      thousandSeparator: '',
    })
  )
