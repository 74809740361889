import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { CartContext } from '@lib/cart/context'
import { UserContext } from '@lib/user/context'
import { getBundleDiscounts } from './bundle'
import { getCartDiscountItems } from './items'
import { getQuantityDiscounts } from './quantity'
import {
  type BundleDiscount,
  type DiscountItem,
  type QuantityDiscount,
} from './types'

interface DiscountContextProps {
  quantityDiscounts: QuantityDiscount[]
  bundleDiscounts: BundleDiscount[]
  cartDiscountItems?: DiscountItem[]
}

interface DiscountContextProviderProps {
  site: SanitySiteFragment
  children: ReactNode
}

export const DiscountContext = createContext<DiscountContextProps>({
  quantityDiscounts: [],
  bundleDiscounts: [],
})

export const DiscountContextProvider = ({
  site,
  children,
}: DiscountContextProviderProps) => {
  const { cart } = useContext(CartContext)
  const { user } = useContext(UserContext)

  const [cartDiscountItems, setCartDiscountItems] = useState<
    DiscountItem[] | undefined
  >()

  const quantityDiscounts = useMemo(
    () => getQuantityDiscounts(site.discounts),
    [site.discounts]
  )
  const bundleDiscounts = useMemo(
    () => getBundleDiscounts(site.discounts),
    [site.discounts]
  )

  // Calculate cart item discounts
  useEffect(() => {
    if (!cart.id) {
      return
    }

    setCartDiscountItems(
      getCartDiscountItems(
        cart.lineItems,
        quantityDiscounts,
        bundleDiscounts,
        user?.company
      )
    )
  }, [bundleDiscounts, cart, quantityDiscounts, user])

  return (
    <DiscountContext.Provider
      value={{
        quantityDiscounts,
        bundleDiscounts,
        cartDiscountItems,
      }}
    >
      {children}
    </DiscountContext.Provider>
  )
}
