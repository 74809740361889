import { type SanityClient } from '@sanity/client'

import {
  productVariantQuery,
  productVariantsByShopifyIdQuery,
  productVariantsQuery,
} from '@data/sanity/queries/product'
import {
  type SanityLocaleQueryParameter,
  type SanityShopifyProductIdQueryParameter,
  type SanityShopifyProductVariantIdQueryParameter,
  type SanityShopifyProductVariantIdsQueryParameter,
} from '@data/sanity/queries/types/parameters'
import {
  type SanityProductVariantQuery,
  type SanityProductVariantsByShopifyIdQuery,
  type SanityProductVariantsQuery,
} from '@data/sanity/queries/types/product'
import { type Locale } from '@lib/language'
import { fetchQuery } from './client'

/**
 * Gets product variant ID from Shopify product variant ID and locale.
 */
export const getSanityProductVariantId = (
  locale: Locale,
  shopifyProductVariantId: number
) => ['productVariant', shopifyProductVariantId, locale].join('-')

/**
 * Fetches a product variant based on its ID.
 */
export const getProductVariant = async (
  sanityClient: SanityClient,
  locale: string,
  shopifyProductVariantId: number
) =>
  fetchQuery<
    SanityProductVariantQuery | null,
    SanityLocaleQueryParameter & SanityShopifyProductVariantIdQueryParameter
  >(sanityClient, productVariantQuery, {
    locale,
    shopifyProductVariantId,
  })

/**
 * Fetches product variants based on a list of ID.
 */
export const getProductVariants = async (
  sanityClient: SanityClient,
  locale: string,
  shopifyProductVariantIds: number[]
) =>
  fetchQuery<
    SanityProductVariantsQuery,
    SanityLocaleQueryParameter & SanityShopifyProductVariantIdsQueryParameter
  >(sanityClient, productVariantsQuery, {
    locale,
    shopifyProductVariantIds,
  })

/**
 * Fetches product variants filtered by Shopify product ID.
 */
export const getProductVariantsByShopifyId = async (
  sanityClient: SanityClient,
  shopifyProductId: number
) =>
  fetchQuery<
    SanityProductVariantsByShopifyIdQuery,
    SanityShopifyProductIdQueryParameter
  >(sanityClient, productVariantsByShopifyIdQuery, {
    shopifyProductId,
  })
