import axios, { type AxiosResponse } from 'axios'

import { ProductValidationError } from '@lib/product/types'
import { type SanityFrameBuilderProductGlass } from '../data/sanity/queries/types/glass'
import {
  type SanityFrameBuilderProductPassepartoutColor,
  type SanityFrameBuilderProductPassepartoutSize,
} from '../data/sanity/queries/types/passepartout'
import { type SanityFrameBuilderProductProfile } from '../data/sanity/queries/types/profile'
import { type SanityFrameBuilderProductProfileTexture } from '../data/sanity/queries/types/profile-texture'
import {
  type SanityFrameBuilderProductSpacerColor,
  type SanityFrameBuilderProductSpacerSize,
} from '../data/sanity/queries/types/spacer'
import { type SanityFrameBuilderProductTexture } from '../data/sanity/queries/types/texture'
import { type SanityFrameBuilderProductConfiguration } from '../data/sanity/queries/types'
import {
  type ShopifyCreateFrameBuilderProductPayload,
  type ShopifyCreateFrameBuilderProductResponse,
} from './product-create'
import { type FrameBuilderState } from './state'
import {
  getIsProductPassepartoutSizeValid,
  getIsProductProfileAvailableForArtSize,
  getIsProductProfileAvailableForPassepartoutColor,
  getIsProductProfileAvailableForSpacerSize,
  getIsProductProfileAvailableForTexture,
} from './validation/product-is-valid'

export type FrameBuilderProductConfiguration =
  SanityFrameBuilderProductConfiguration

export type FrameBuilderProductProfile = SanityFrameBuilderProductProfile

export type FrameBuilderProductTexture = SanityFrameBuilderProductTexture

export type FrameBuilderProductProfileTexture =
  SanityFrameBuilderProductProfileTexture

export type FrameBuilderProductPassepartoutSize =
  SanityFrameBuilderProductPassepartoutSize

export type FrameBuilderProductPassepartoutColor =
  SanityFrameBuilderProductPassepartoutColor

export type FrameBuilderProductGlass = SanityFrameBuilderProductGlass

export type FrameBuilderProductSpacerSize = SanityFrameBuilderProductSpacerSize

export type FrameBuilderProductSpacerColor =
  SanityFrameBuilderProductSpacerColor

interface FrameBuilderProductArtwork {
  width: number
  height: number
}

export interface FrameBuilderProduct {
  artwork: FrameBuilderProductArtwork
  texture: FrameBuilderProductTexture
  profile: FrameBuilderProductProfile
  passepartoutSize: FrameBuilderProductPassepartoutSize
  passepartoutColor?: FrameBuilderProductPassepartoutColor
  glass: FrameBuilderProductGlass
  spacerSize?: FrameBuilderProductSpacerSize
  spacerColor?: FrameBuilderProductSpacerColor
}

export const shopifyFrameBuilderProductType = 'Generated Frame'

/**
 * Gets frame builder product data from payload.
 */
export const getFrameBuilderProductFromPayload = (
  payload: ShopifyCreateFrameBuilderProductPayload,
  configuration: FrameBuilderProductConfiguration
) => {
  // Resolve profiles and textures
  const profile = configuration.profiles.find(
    (profile) => profile._id === payload.profileId
  )
  const texture = configuration.textures.find(
    (texture) => texture._id === payload.textureId
  )

  // Resolve passepartout sizes & colors
  const passepartoutSize = configuration.passepartoutSizes.find(
    (passepartoutSize) => passepartoutSize._id === payload.passepartoutSizeId
  )
  const passepartoutColor = configuration.passepartoutColors.find(
    (passepartoutColor) => passepartoutColor._id === payload.passepartoutColorId
  )

  // Resolve glass
  const glass = configuration.glass.find(
    (glass) => glass._id === payload.glassId
  )

  // Resolve spacer sizes & colors
  const spacerSize = configuration.spacerSizes.find(
    (spacerSize) => spacerSize._id === payload.spacerSizeId
  )
  const spacerColor = configuration.spacerColors.find(
    (spacerColor) => spacerColor._id === payload.spacerColorId
  )

  const frameWidth = payload.artwork.width + (passepartoutSize?.size ?? 0) * 2
  const frameHeight = payload.artwork.height + (passepartoutSize?.size ?? 0) * 2

  if (typeof texture === 'undefined') {
    throw new ProductValidationError('Frame texture is missing.')
  }

  if (typeof profile === 'undefined') {
    throw new ProductValidationError('Frame moulding is missing.')
  }

  if (typeof passepartoutSize === 'undefined') {
    throw new ProductValidationError('Frame passepartout size is missing.')
  }

  if (typeof glass === 'undefined') {
    throw new ProductValidationError('Frame glass is missing.')
  }

  const isProductProfileAvailableForArtSize =
    getIsProductProfileAvailableForArtSize([frameWidth, frameHeight], profile)

  if (!isProductProfileAvailableForArtSize) {
    throw new ProductValidationError(
      'Frame moulding is invalid for frame size.'
    )
  }

  const isProductProfileAvailableForTexture =
    getIsProductProfileAvailableForTexture(
      configuration.profileTextures,
      profile,
      texture
    )

  if (!isProductProfileAvailableForTexture) {
    throw new ProductValidationError(
      'Frame moulding and texture are incompatible.'
    )
  }

  const isProductPassepartoutSizeValid = getIsProductPassepartoutSizeValid(
    frameWidth,
    frameHeight,
    passepartoutSize,
    passepartoutColor
  )

  if (!isProductPassepartoutSizeValid) {
    throw new ProductValidationError('Frame passepartout size is invalid.')
  }

  const isProductProfileAvailableForPassepartoutColor =
    getIsProductProfileAvailableForPassepartoutColor(profile, passepartoutColor)

  if (
    passepartoutSize.size > 0 &&
    (typeof passepartoutColor === 'undefined' ||
      !isProductProfileAvailableForPassepartoutColor)
  ) {
    throw new ProductValidationError('Frame passepartout color is invalid.')
  }

  const isProductProfileAvailableForSpacerSize =
    getIsProductProfileAvailableForSpacerSize(profile, spacerSize)

  if (!isProductProfileAvailableForSpacerSize) {
    throw new ProductValidationError('Frame spacer size is invalid.')
  }

  const product: FrameBuilderProduct = {
    artwork: {
      width: payload.artwork.width,
      height: payload.artwork.height,
    },
    profile,
    texture,
    passepartoutSize: passepartoutSize,
    passepartoutColor: passepartoutColor,
    glass: glass,
    spacerSize: spacerSize,
    spacerColor: spacerColor,
  }

  return product
}

/**
 * Sends a request to create a frame builder product.
 */
export const createFrameBuilderProduct = async (
  locale: string,
  state: FrameBuilderState,
  imageDataUri: string
) => {
  try {
    const payload: ShopifyCreateFrameBuilderProductPayload = {
      artwork: {
        width: state.artwork.width,
        height: state.artwork.height,
      },
      textureId: state.texture?._id,
      profileId: state.profile?._id,
      passepartoutSizeId: state.passepartoutSize?._id,
      passepartoutColorId: state.passepartoutColor?._id,
      glassId: state.glass?._id,
      spacerSizeId: state.spacerSize?._id,
      spacerColorId: state.spacerColor?._id,
      image: imageDataUri,
    }

    const response = await axios.post<
      ShopifyCreateFrameBuilderProductResponse,
      AxiosResponse<ShopifyCreateFrameBuilderProductResponse>,
      string
    >('/api/shopify/create-frame-product', JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
        'X-Locale': locale,
      },
    })

    if (response.status !== 200) {
      throw new Error(JSON.stringify(response.data))
    }

    return response.data
  } catch (error) {
    console.log(error)
  }
}
