import { type SessionOptions } from 'iron-session'

import {
  type SanityCustomerAddress,
  type SanityCustomerDocument,
} from '@data/sanity/queries/types/customer'
import { CountryCode } from '@data/shopify/admin/types'
import { type AddressFormValues } from '@lib/address'
import { getSanityClient } from '@lib/sanity/client'
import { getCustomerByEmail } from '@lib/sanity/customer'
import { getShopifyGlobalId } from '@lib/shopify/helpers'
import { type StoredUser, type UserAddress } from './types'

const loggedOutUser: StoredUser = {
  isLoggedIn: false,
}

export const sessionOptions: SessionOptions = {
  password: {
    1: process.env.SECRET_COOKIE_PASSWORD ?? '',
  },
  cookieName: 'adj_auth',
}

/**
 * Gets user address from customer address.
 */
const getUserAddress = (
  customer: SanityCustomerDocument,
  address: SanityCustomerAddress
) => {
  const shopifyAddressId = getShopifyGlobalId(
    'MailingAddress',
    address.addressId,
    [
      {
        name: 'model_name',
        value: 'CustomerAddress',
      },
    ]
  )

  const formattedAddress = [
    `${address.firstName ?? ''} ${address.lastName ?? ''}`.trim(),
    address.company ?? '',
    `${address.addressLine1 ?? ''} ${address.addressLine2 ?? ''}`.trim(),
    `${address.zip ?? ''} ${address.city ?? ''}`.trim(),
    address.country ?? '',
  ].filter(Boolean) as string[]

  const addressFormValues: AddressFormValues = {
    firstName: address.firstName ?? '',
    lastName: address.lastName ?? '',
    company: address.company ?? '',
    address1: address.addressLine1 ?? '',
    address2: address.addressLine2 ?? '',
    city: address.city ?? '',
    country: address.country,
    zip: address.zip,
    phone: customer.phone ?? '',
    isDefault: address.isDefault,
  }

  const userAddress: UserAddress = {
    id: shopifyAddressId,
    globalId: address.addressId,
    isDefault: address.isDefault,
    formatted: formattedAddress,
    countryCode: address.countryCode as CountryCode,
    values: addressFormValues,
  }

  return userAddress
}

/**
 * Gets stored user details including customer, company and address data.
 */
export const getStoredUser = async (user?: StoredUser) => {
  if (!user?.token || !user?.email) {
    return loggedOutUser
  }

  const newUser: StoredUser = {
    ...user,
  }

  // Get customer from Sanity
  const sanityClient = getSanityClient()
  const customer = await getCustomerByEmail(sanityClient, user.email)

  if (customer) {
    newUser.customer = {
      customerId: customer.customerId,
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      phone: customer.phone,
      outOfSync: customer.outOfSync,
      lastSyncAt: customer.lastSyncAt,
    }
  }

  if (customer?.company) {
    newUser.company = customer.company
  }

  if (customer?.addresses) {
    newUser.addresses = customer.addresses.map((address) =>
      getUserAddress(customer, address)
    )
  }

  return newUser
}

/**
 * Determines whether user is a B2B client.
 */
export const getIsUserB2bClient = (user?: StoredUser) => !!user?.company?._id

/**
 * Determines whether user has permission to purchase products.
 */
export const getHasUserPurchasePermission = (user?: StoredUser) =>
  typeof user !== 'undefined' && user.isLoggedIn
