import cx from 'classnames'
import {
  type ChangeEvent,
  type FocusEvent,
  type HTMLInputAutoCompleteAttribute,
  type ReactNode,
  forwardRef,
  useId,
} from 'react'

export interface InputFieldWithManagedStateProps {
  type: 'text' | 'email' | 'number' | 'password'
  name?: string
  value?: string
  setValue: (newValue: string) => void
  label?: string | ReactNode
  isRequired?: boolean
  placeholder?: string
  max?: string | number
  maxLength?: number
  autoComplete?: HTMLInputAutoCompleteAttribute
  errorMessage?: string
  noBorder?: boolean
  borderBottom?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  className?: string
  inputClassName?: string
  children?: ReactNode
}

const InputFieldWithManagedState = forwardRef<
  HTMLInputElement,
  InputFieldWithManagedStateProps
>(
  (
    {
      type,
      name,
      value,
      setValue,
      label,
      isRequired,
      placeholder,
      max,
      maxLength,
      autoComplete,
      errorMessage,
      noBorder,
      borderBottom,
      onChange,
      onBlur,
      className,
      inputClassName,
      children,
    },
    ref
  ) => {
    const id = useId()

    const inputMode =
      type === 'email' ? 'email' : type === 'number' ? 'decimal' : 'text'

    return (
      <div className={cx('grid', className)}>
        <div className="flex flex-col relative text-left text-sm">
          {label && (
            <label htmlFor={id} className="font-medium mb-2">
              {label}
              {!!isRequired && <span className="ml-0.5 text-red">*</span>}
            </label>
          )}

          <div
            className={cx('flex justify-between', {
              'border-error': errorMessage,
              'border-input-border': !errorMessage,
              'mb-[1px] border-b': !noBorder && borderBottom,
              'border rounded': !noBorder && !borderBottom,
            })}
          >
            <input
              ref={ref}
              id={id}
              type={type}
              inputMode={inputMode}
              max={max}
              maxLength={maxLength}
              autoComplete={autoComplete}
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={(event) => {
                if (value !== event.target.value) {
                  setValue(event.target.value)
                }

                if (onChange) {
                  onChange(event)
                }
              }}
              onBlur={(event) => {
                if (value !== event.target.value) {
                  setValue(event.target.value)
                }

                if (onBlur) {
                  onBlur(event)
                }
              }}
              className={cx(
                'relative appearance-none w-full h-full py-3 leading-none bg-input-bg text-input-text',
                {
                  'px-4': !noBorder && !borderBottom,
                },
                inputClassName
              )}
            />
            {children}
          </div>

          {errorMessage && (
            <span role="alert" className="mt-2 font-medium text-xs text-error">
              {errorMessage}
            </span>
          )}
        </div>
      </div>
    )
  }
)

InputFieldWithManagedState.displayName = 'InputFieldWithManagedState'

export default InputFieldWithManagedState
