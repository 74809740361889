import { gql } from '@apollo/client'

import { moneyFragment } from './common'

const cartDeliveryOptionFragment = gql`
  fragment CartDeliveryOptionFragment on CartDeliveryOption {
    title
    description
    estimatedCost {
      amount
      currencyCode
    }
  }
`

const cartDeliveryFragmentFragment = gql`
  fragment CartDeliveryFragment on CartDelivery {
    addresses {
      id
      selected
      address {
        ... on CartDeliveryAddress {
          address1
          address2
          city
          countryCode
          zip
        }
      }
    }
  }
`

export const cartFragment = gql`
  ${cartDeliveryFragmentFragment}
  ${cartDeliveryOptionFragment}
  ${moneyFragment}

  fragment CartFragment on Cart {
    id
    checkoutUrl
    buyerIdentity {
      customer {
        id
      }
    }
    lines(first: 25) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          merchandise {
            ... on ProductVariant {
              id
              title
              price {
                amount
                currencyCode
              }
              image {
                url
              }
              selectedOptions {
                name
                value
              }
              product {
                id
                title
                productType
              }
            }
          }
          discountAllocations {
            ... on CartAutomaticDiscountAllocation {
              title
              discountedAmount {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
    discountCodes {
      applicable
      code
    }
    cost {
      subtotalAmount {
        ...MoneyFragment
      }
      totalDutyAmount {
        ...MoneyFragment
      }
      totalTaxAmount {
        ...MoneyFragment
      }
      totalAmount {
        ...MoneyFragment
      }
    }
    delivery {
      ...CartDeliveryFragment
    }
    deliveryGroups(first: 25) {
      edges {
        node {
          deliveryOptions {
            ...CartDeliveryOptionFragment
          }
          selectedDeliveryOption {
            ...CartDeliveryOptionFragment
          }
        }
      }
    }
  }
`

export const cartUserErrorFragment = gql`
  fragment CartUserErrorFragment on CartUserError {
    code
    field
    message
  }
`

export const cartWarningFragment = gql`
  fragment CartWarningFragment on CartWarning {
    code
    message
    target
  }
`
