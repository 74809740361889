import { type ReactNode, createContext } from 'react'

import { useUser } from './hooks'
import { type MutateUser, type StoredUser } from './types'

interface UserContextProps {
  user?: StoredUser
  mutateUser: MutateUser
}

interface UserContextProviderProps {
  children: ReactNode
}

export const UserContext = createContext<UserContextProps>({
  mutateUser: async () => undefined,
})

/**
 * The user context provider.
 */
export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const { user, mutateUser } = useUser()

  return (
    <UserContext.Provider
      value={{
        user,
        mutateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
