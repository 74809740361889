import { useContext, useEffect, useState } from 'react'

import { type SanityBlogSettings } from '@data/sanity/queries/types/blog'
import { type SanityProductVariantFragment } from '@data/sanity/queries/types/product'
import { type Locale } from './language'
import { LanguageContext } from './language-context'
import { MetadataContext } from './metadata-context'
import { type PageType, getPageUrl } from './routes'

/**
 * Blog canonical URL hook.
 */
export const useBlogCanonicalUrl = (
  pageType: PageType,
  slug: string,
  blogSettings?: SanityBlogSettings
) => {
  const { locale } = useContext(LanguageContext)

  const [canonicalUrl, setCanonicalUrl] = useState<string>()

  useEffect(() => {
    // Check if blog inheritance is set
    if (
      typeof window === 'undefined' ||
      !blogSettings?.inheritContent ||
      !blogSettings?.sourceLocale
    ) {
      return
    }

    const origin = window.location.origin
    const pageUrl = getPageUrl({
      locale: blogSettings.sourceLocale as Locale,
      pageType,
      slugs: slug,
    })
    setCanonicalUrl(`${origin}${pageUrl}`)
  }, [locale, pageType, slug, blogSettings])

  return canonicalUrl
}

/**
 * Product page variant metadata hook.
 */
export const useVariantMetadata = (variant?: SanityProductVariantFragment) => {
  const { setMetadataOverride } = useContext(MetadataContext)

  // Set product variant metadata override
  useEffect(() => {
    setMetadataOverride(variant?.seo ?? null)

    return () => {
      setMetadataOverride(null)
    }
  }, [variant, setMetadataOverride])
}
