import cx from 'classnames'
import { useMemo } from 'react'

import { type SanityStrings } from '@data/sanity/queries/types/site'
import { getPriceWithCurrency } from '@lib/format'

type PriceBadgeSize = 'sm' | 'lg'

export type PriceBadgeStrings = Pick<
  SanityStrings,
  'productPricePrefix' | 'productDiscountText' | 'productTaxIncludedText'
>

export interface PriceBadgeProps {
  strings: PriceBadgeStrings
  price?: number
  comparePrice?: number
  currency?: string
  taxRate?: number
  size?: PriceBadgeSize
  showPrice?: boolean
  showOldPrice?: boolean
  showPricePrefix?: boolean
  showPriceTaxSuffix?: boolean
  showCurrency?: boolean
  showDiscountPercent?: boolean
  className?: string
}

const PriceBadge = ({
  strings,
  price,
  comparePrice,
  currency,
  taxRate,
  size,
  showPrice,
  showOldPrice,
  showPricePrefix,
  showPriceTaxSuffix,
  showCurrency,
  showDiscountPercent,
  className,
}: PriceBadgeProps) => {
  const formattedPrice = useMemo(() => {
    if (typeof price === 'undefined') {
      return
    }

    const pricePrefix =
      showPricePrefix && strings.productPricePrefix
        ? `${strings.productPricePrefix} `
        : ''
    const priceWithCurrency = getPriceWithCurrency(price, currency, {
      taxRate,
      showCurrency,
      hasTrailingZeros: true,
    })

    return `${pricePrefix}${priceWithCurrency}`
  }, [currency, price, showCurrency, showPricePrefix, strings, taxRate])

  const formattedOldPrice = useMemo(() => {
    if (typeof comparePrice === 'undefined') {
      return
    }

    const priceWithCurrency = getPriceWithCurrency(comparePrice, currency, {
      taxRate,
      showCurrency,
      hasTrailingZeros: true,
    })
    return priceWithCurrency
  }, [comparePrice, currency, showCurrency, taxRate])

  const discountPercent = useMemo(() => {
    if (typeof price === 'undefined' || typeof comparePrice === 'undefined') {
      return
    }

    const minPrice = Math.min(price, comparePrice)
    const maxPrice = Math.max(price, comparePrice)
    const discountPercent = ((maxPrice - minPrice) / maxPrice) * 100

    // If discount is 0-1%, return 1%
    if (discountPercent > 0 && discountPercent < 1) {
      return '1'
    }

    const roundedDiscountPercent = Math.round(discountPercent).toString()
    return roundedDiscountPercent
  }, [price, comparePrice])

  const formattedDiscountPercent = useMemo(() => {
    if (typeof discountPercent === 'undefined') {
      return
    }

    const discountText = strings.productDiscountText.replace(
      /{percent}/gi,
      discountPercent
    )
    return discountText
  }, [discountPercent, strings])

  const formattedPriceTaxSuffix = useMemo(() => {
    if (typeof taxRate === 'undefined') {
      return
    }

    const text = strings.productTaxIncludedText.replace(
      '{percent}',
      `${taxRate * 100}`
    )
    return `(${text})`
  }, [strings, taxRate])

  const hasPrice = !!showPrice && !!formattedPrice
  const hasOldPrice = !!showOldPrice && !!formattedOldPrice
  const hasDiscountPercent =
    (hasPrice || hasOldPrice) &&
    !!showDiscountPercent &&
    !!formattedDiscountPercent
  const hasPriceTaxSuffix =
    (hasPrice || hasOldPrice) &&
    !!showPriceTaxSuffix &&
    !!formattedPriceTaxSuffix
  const isSmallGap =
    hasPrice && hasPriceTaxSuffix && !hasOldPrice && !hasDiscountPercent

  return (
    <div
      className={cx(
        'inline-flex shrink-0 flex-wrap items-center gap-y-1 leading-normal',
        {
          'gap-x-3': !isSmallGap,
          'gap-x-1': isSmallGap,
          'font-medium': size !== 'sm',
          'text-sm': size === 'sm',
          'text-lg': size === 'lg',
        },
        className
      )}
    >
      {hasPrice && <span>{formattedPrice}</span>}

      {hasOldPrice && (
        <span
          className={cx('line-through', {
            'text-sm': !size,
            'text-xs': size === 'sm',
            'text-base': size === 'lg',
          })}
        >
          {formattedOldPrice}
        </span>
      )}

      {hasDiscountPercent && (
        <span
          className={cx({
            'text-sm': !size,
            'text-xs': size === 'sm',
            'text-base': size === 'lg',
          })}
        >
          {formattedDiscountPercent}
        </span>
      )}

      {hasPriceTaxSuffix && (
        <span
          className={cx({
            'text-base': size === 'lg',
          })}
        >
          {formattedPriceTaxSuffix}
        </span>
      )}
    </div>
  )
}

export default PriceBadge
