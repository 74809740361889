import { type CartAddress } from './cart/types'
import { defaultCountryCodes, englishCountryNames } from './country'
import { type Locale } from './language'

export interface AddressFormValues {
  firstName: string
  lastName: string
  company: string
  address1: string
  address2: string
  city: string
  country: string
  zip: string
  phone: string
  isDefault: boolean
}

/**
 * Gets initial values of the address form.
 */
export const getAddressFormInitialValues = (locale: Locale) => {
  const initialValues: AddressFormValues = {
    firstName: '',
    lastName: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    country: englishCountryNames[defaultCountryCodes[locale]],
    zip: '',
    phone: '',
    isDefault: false,
  }
  return initialValues
}

/**
 * Gets a unique key from address form values.
 */
export const getAddressFormValuesKey = (
  countryCode: string,
  values: AddressFormValues
) => {
  const keyParts = [
    values.address1,
    values.address2,
    values.city,
    countryCode,
    values.zip,
  ].filter(Boolean)

  return keyParts.join('|')
}

/**
 * Gets a unique key from cart address.
 */
export const getCartAddressKey = (cartAddress: CartAddress) => {
  const keyParts = [
    cartAddress.address1,
    cartAddress.address2,
    cartAddress.city,
    cartAddress.countryCode,
    cartAddress.zip,
  ].filter(Boolean)

  return keyParts.join('|')
}
