import {
  type SanityBundleDiscount,
  type SanityDiscount,
  type SanityDiscountProduct,
} from '@data/sanity/queries/types/discount'
import { sanityProductIdToShopifyId } from '@lib/product/product'
import { type VariantLineItem } from '@lib/cart/types'
import { compareNumbers } from '@lib/helpers'
import { getAllVariantGroups, getValueCounts } from './helpers'
import {
  type BundleDiscount,
  type BundleDiscountWithVariants,
  type DiscountItem,
  type ValueCounts,
} from './types'

/**
 * Gets bundle discounts from all discounts.
 */
export const getBundleDiscounts = (discounts: SanityDiscount[]) => {
  const bundleDiscounts = discounts.filter(
    (discount) => discount.type === 'bundle'
  ) as SanityBundleDiscount[]

  return bundleDiscounts.map((bundleDiscount) => {
    const newBundleDiscount: BundleDiscount = {
      id: bundleDiscount._id,
      title: bundleDiscount.title,
      discountValuePercent: bundleDiscount.discountValuePercent,
      productQuantity: bundleDiscount.products.length,
      doNotStackWithQuantityDiscounts:
        !!bundleDiscount.doNotStackWithQuantityDiscounts,
      variantGroups: getBundleDiscountVariantGroups(bundleDiscount.products),
    }

    return newBundleDiscount
  })
}

/**
 * Gets groups of variant IDs joined in a string (e.g., ["v1,v2", "v1,v3", ...]).
 */
const getBundleDiscountVariantGroups = (
  products: SanityDiscountProduct[]
): string[] => {
  // Get variant IDs grouped by products
  const variantsByProduct: number[][] = []
  products.forEach((product) => {
    const variants: number[] = []

    product.variantIds.forEach((variantId) => {
      const productVariantShopifyId = sanityProductIdToShopifyId(variantId)

      if (productVariantShopifyId) {
        variants.push(productVariantShopifyId)
      }
    })

    variantsByProduct.push(variants)
  })

  // Create a group of all possible variant mappings
  const productQuantity = products.length ?? 0
  const getVariantGroups = (productIndex = 0) => {
    if (productIndex >= productQuantity - 1) {
      return variantsByProduct[productIndex].map((variantId) => [variantId])
    }

    const result: number[][] = []
    const variantGroups = getVariantGroups(productIndex + 1)

    variantsByProduct[productIndex].forEach((variantId) => {
      variantGroups.forEach((variantIds) => {
        result.push([variantId, ...variantIds])
      })
    })

    return result
  }

  // Sort and merge variant IDs in the group
  return getVariantGroups().map((variantIds) =>
    variantIds.sort(compareNumbers).join(',')
  )
}

/**
 * Gets matching bundle discount.
 */
const getMatchingBundleDiscount =
  (
    allVariantGroups: Record<number, string[]>,
    unusedVariantIdCounts: ValueCounts
  ) =>
  (bundleDiscount: BundleDiscount) => {
    const quantity = bundleDiscount.productQuantity

    if (!allVariantGroups[quantity]) {
      allVariantGroups[quantity] = getAllVariantGroups(
        unusedVariantIdCounts,
        quantity
      )
    }

    // Check if bundle contains any of the possible variant variations
    const matchingVariantGroup = allVariantGroups[quantity].find(
      (variantGroup) => bundleDiscount.variantGroups.includes(variantGroup)
    )

    if (!matchingVariantGroup) {
      return null
    }

    return {
      ...bundleDiscount,
      variantIds: matchingVariantGroup
        .split(',')
        .map((variantId) => Number(variantId)),
    }
  }

/**
 * Gets discount amounts for bundle discounts.
 */
const getBundleDiscountAmounts =
  (variantLineItems: VariantLineItem[]) =>
  (matchedBundleDiscount: BundleDiscountWithVariants) => {
    // Get variant line items from bundle variant IDs
    const matchedVariantLineItems = matchedBundleDiscount.variantIds
      .map((variantId) =>
        variantLineItems.find(
          (variantLineItem) => variantLineItem.id === variantId
        )
      )
      .filter(Boolean) as VariantLineItem[]

    const totalPrice = matchedVariantLineItems.reduce(
      (total, variantLineItem) => total + variantLineItem.price,
      0
    )
    const discountRate =
      typeof matchedBundleDiscount.discountValuePercent !== 'undefined'
        ? matchedBundleDiscount.discountValuePercent / 100
        : 0

    return {
      id: matchedBundleDiscount.id,
      amount: totalPrice * discountRate,
    }
  }

/**
 * Gets the next active bundle discount.
 */
const getNextActiveBundleDiscount = (
  variantLineItems: VariantLineItem[],
  bundleDiscounts: BundleDiscount[],
  unusedVariantIds: number[]
): BundleDiscountWithVariants | null => {
  const minimumProductQuantity = Math.min(
    ...bundleDiscounts.map((bundleDiscount) => bundleDiscount.productQuantity)
  )

  // Check if there are not enough unused variants to potentially form a bundle
  if (unusedVariantIds.length < minimumProductQuantity) {
    return null
  }

  // Find all possible bundle discounts from given variants
  const allVariantGroups: Record<number, string[]> = {}
  const unusedVariantIdCounts = getValueCounts(unusedVariantIds)
  const matchedBundleDiscounts = bundleDiscounts
    .map(getMatchingBundleDiscount(allVariantGroups, unusedVariantIdCounts))
    .filter(Boolean) as BundleDiscountWithVariants[]

  if (matchedBundleDiscounts.length < 2) {
    return matchedBundleDiscounts[0] ?? null
  }

  // Calculate discount amounts for found bundle discounts and sort them by amount in descending order
  const discountAmounts = matchedBundleDiscounts.map(
    getBundleDiscountAmounts(variantLineItems)
  )
  discountAmounts.sort(
    (bundleDiscount1, bundleDiscount2) =>
      bundleDiscount2.amount - bundleDiscount1.amount
  )

  // Return the bundle with the largest discount amount
  return (
    matchedBundleDiscounts.find(
      (bundleDiscount) => bundleDiscount.id === discountAmounts[0].id
    ) ?? matchedBundleDiscounts[0]
  )
}

/**
 * Gets all active bundle discounts for given variant IDs.
 */
const getActiveBundleDiscounts = (
  variantLineItems: VariantLineItem[],
  bundleDiscounts: BundleDiscount[],
  limit = 1000
) => {
  const variantIds = variantLineItems
    .map((variantLineItem) =>
      new Array(variantLineItem.quantity)
        .fill(null)
        .map(() => variantLineItem.id)
    )
    .flat(1)
  const unusedVariantIds = [...variantIds]
  const activeBundleDiscounts: BundleDiscountWithVariants[] = []
  let count = 0

  // Find the next active bundle discount
  let activeBundleDiscount = getNextActiveBundleDiscount(
    variantLineItems,
    bundleDiscounts,
    unusedVariantIds
  )

  while (activeBundleDiscount) {
    activeBundleDiscounts.push(activeBundleDiscount)

    // Remove variant IDs from unused variants
    activeBundleDiscount.variantIds.forEach((variantId) => {
      const index = unusedVariantIds.indexOf(variantId)

      if (index > -1) {
        unusedVariantIds.splice(index, 1)
      }
    })

    // Limit the total count
    count += 1

    if (count >= limit) {
      break
    }

    // Find the next active bundle discount
    activeBundleDiscount = getNextActiveBundleDiscount(
      variantLineItems,
      bundleDiscounts,
      unusedVariantIds
    )
  }

  return activeBundleDiscounts
}

/**
 * Gets bundle discount items for variant line items.
 */
export const getBundleDiscountItems = (
  variantLineItems: VariantLineItem[],
  bundleDiscounts: BundleDiscount[]
) => {
  const discountItems: DiscountItem[] = []

  // Get active bundle discounts
  const activeBundleDiscounts = getActiveBundleDiscounts(
    variantLineItems,
    bundleDiscounts
  )

  activeBundleDiscounts.forEach((activeBundleDiscount) => {
    // Do not add duplicate bundles (use quantity instead)
    const hasDiscountItem = discountItems.some(
      (discountItem) => discountItem.id === activeBundleDiscount.id
    )

    if (hasDiscountItem) {
      return
    }

    // Get the total price of bundle variants
    const bundleItemPrice = variantLineItems
      .filter((variantLineItem) =>
        activeBundleDiscount.variantIds.includes(variantLineItem.id)
      )
      .reduce((total, variantLineItem) => total + variantLineItem.price, 0)
    const activeBundleDiscountRate =
      typeof activeBundleDiscount.discountValuePercent !== 'undefined'
        ? activeBundleDiscount.discountValuePercent / 100
        : 0

    const discountQuantity = activeBundleDiscounts.filter(
      (bundleDiscount) => bundleDiscount.id === activeBundleDiscount.id
    ).length

    discountItems.push({
      id: activeBundleDiscount.id,
      type: 'bundle',
      title: activeBundleDiscount.title,
      amount: bundleItemPrice * activeBundleDiscountRate,
      quantity: discountQuantity,
      discountValuePercent: activeBundleDiscount.discountValuePercent,
    })
  })

  return discountItems
}
