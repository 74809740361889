import cx from 'classnames'
import { useContext } from 'react'

import { StringsContext } from '@lib/strings-context'

import ProductPrice from '@blocks/product/product-price'

interface CartSummarySubtotalProps {
  price: number
  className?: string
}

const CartSummarySubtotal = ({
  price,
  className,
}: CartSummarySubtotalProps) => {
  const strings = useContext(StringsContext)

  return (
    <div
      className={cx(
        'text-sm flex gap-x-5 justify-between font-medium border-b py-3.5',
        className
      )}
    >
      <span>{strings.cartSubtotal}</span>

      <ProductPrice price={price} showPrice showCurrency className="shrink-0" />
    </div>
  )
}

export default CartSummarySubtotal
