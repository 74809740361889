import cx from 'classnames'
import FocusTrap from 'focus-trap-react'
import { motion } from 'framer-motion'
import {
  type KeyboardEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { type SanityCartSettings } from '@data/sanity/queries/types/cart'
import { CartContext } from '@lib/cart/context'
import { getCartItemCount } from '@lib/cart/helpers'
import { type ErrorMessages } from '@lib/request'
import { StringsContext } from '@lib/strings-context'

import Button from '@components/buttons/button'
import DrawerBackdrop from '@components/drawer/backdrop'
import CommentField from './comment-field'
import CartLineItems from './line-items'
import CartSubmit from './submit'
import CartSummary from './summary'
import VatIdField from './vat-id-field'

interface CartModalProps {
  cartSettings?: SanityCartSettings
}

const CartModal = ({ cartSettings }: CartModalProps) => {
  const {
    cart,
    isCartOpen,
    isCartUpdating,
    isCartSubmitting,
    openCartInModal,
    toggleCart,
  } = useContext(CartContext)
  const strings = useContext(StringsContext)

  const [hasFocus, setHasFocus] = useState(false)
  const [vatId, setVatId] = useState('')
  const [comment, setComment] = useState('')
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})

  const cartItemCount = useMemo(() => getCartItemCount(cart), [cart])
  const lineItems = useMemo(() => cart?.lineItems ?? [], [cart])
  const cartFormValues = useMemo(
    () => ({
      vatId,
      comment,
    }),
    [comment, vatId]
  )

  const handleKeyup = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        toggleCart(false)
      }
    },
    [toggleCart]
  )

  const handleVatIdChange = useCallback(
    (newValue: string) => setVatId(newValue),
    []
  )

  const handleCommentChange = useCallback(
    (newValue: string) => setComment(newValue),
    []
  )

  const handleCartCloseClick = useCallback(
    () => toggleCart(false),
    [toggleCart]
  )

  const handleCartSubmitClick = useCallback(
    () => toggleCart(false),
    [toggleCart]
  )

  if (!cartSettings || !openCartInModal) {
    return null
  }

  return (
    <>
      <FocusTrap
        active={isCartOpen && hasFocus}
        focusTrapOptions={{
          allowOutsideClick: true,
        }}
      >
        <motion.div
          initial="hide"
          animate={isCartOpen ? 'show' : 'hide'}
          variants={{
            show: { x: '0%' },
            hide: { x: '100%' },
          }}
          transition={{ duration: 0.8, ease: [0.16, 1, 0.3, 1] }}
          onKeyUp={(event) => handleKeyup(event)}
          onAnimationComplete={() => setHasFocus(isCartOpen)}
          className={cx(
            'fixed top-0 right-0 w-full h-screen max-w-3xl z-90 bg-pageBG text-pageText pointer-events-none transition-visibility',
            {
              invisible: !isCartOpen,
              'pointer-events-auto transition delay-[0s]': isCartOpen,
              'cursor-wait': isCartUpdating || isCartSubmitting,
            }
          )}
        >
          <div className="flex flex-col relative h-full">
            <div className="flex items-center justify-between p-5 border-b">
              <h3>
                {strings.cartTitle} ({cartItemCount})
              </h3>

              <Button
                variant="outlined"
                color="gray"
                size="xs"
                icon="Close"
                onClick={handleCartCloseClick}
              />
            </div>

            <div className="flex-1 flex flex-col overflow-y-scroll no-scrollbar p-5">
              <CartLineItems
                lineItems={lineItems}
                itemClassName="[&:last-of-type]:border-0"
              />
            </div>

            {lineItems.length > 0 && (
              <div className="pb-5 border-t">
                <CartSummary className="px-5" />

                {!!cartSettings.showVatId && (
                  <VatIdField
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                    onChange={handleVatIdChange}
                    className="px-5"
                  />
                )}

                {!!cartSettings.showComment && (
                  <CommentField
                    onChange={handleCommentChange}
                    className="px-5"
                  />
                )}

                <CartSubmit
                  cartSettings={cartSettings}
                  cartFormValues={cartFormValues}
                  setErrorMessages={setErrorMessages}
                  onClick={handleCartSubmitClick}
                  className="mt-5 px-5"
                >
                  {strings.cartSubmit}
                </CartSubmit>

                {!!cartSettings.message && (
                  <p className="mt-4 px-5 text-center text-sm font-medium">
                    {cartSettings.message}
                  </p>
                )}
              </div>
            )}
          </div>
        </motion.div>
      </FocusTrap>

      <DrawerBackdrop isOpen={isCartOpen} onClick={() => toggleCart(false)} />
    </>
  )
}

export default CartModal
