import cx from 'classnames'
import { useContext } from 'react'

import { StringsContext } from '@lib/strings-context'

import ProductPrice from '@blocks/product/product-price'

interface CartSummaryDiscountProps {
  amount: number
  title?: string
  quantity?: number
  className?: string
}

const CartSummaryDiscount = ({
  amount,
  title,
  quantity,
  className,
}: CartSummaryDiscountProps) => {
  const strings = useContext(StringsContext)

  const totalAmount = amount * (quantity ?? 1)

  return (
    <div
      className={cx(
        'text-sm flex gap-x-5 justify-between font-medium border-b py-3.5',
        className
      )}
    >
      <div className="inline-flex flex-wrap gap-x-5 items-center">
        {!!strings.cartDiscount && <span>{strings.cartDiscount}</span>}
        <span className="text-gray-700">
          {title}
          {!!quantity && `(x${quantity})`}
        </span>
      </div>

      <ProductPrice
        price={totalAmount}
        showPrice
        showCurrency
        className="shrink-0"
      />
    </div>
  )
}

export default CartSummaryDiscount
