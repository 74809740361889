import { gql } from '@apollo/client'

export const moneyFragment = gql`
  fragment MoneyFragment on MoneyV2 {
    amount
    currencyCode
  }
`

export const mailingAddressFragment = gql`
  fragment MailingAddressFragment on MailingAddress {
    id
    formatted(withName: true)
    firstName
    lastName
    company
    address1
    address2
    city
    country
    countryCodeV2
    zip
    phone
  }
`

export const orderFragment = gql`
  fragment OrderFragment on Order {
    id
    name
    processedAt
    financialStatus
    fulfillmentStatus
    totalPrice {
      amount
    }
    statusUrl
  }
`

export const orderProductFragment = gql`
  fragment OrderProductFragment on Order {
    id
    lineItems(first: 25) {
      edges {
        node {
          variant {
            id
            product {
              id
              handle
              title
            }
          }
        }
      }
    }
  }
`

export const shopFragment = gql`
  fragment ShopFragment on Shop {
    id
    name
    moneyFormat
    primaryDomain {
      host
    }
    paymentSettings {
      countryCode
      currencyCode
    }
  }
`

export const userErrorFragment = gql`
  fragment UserErrorFragment on UserError {
    field
    message
  }
`
