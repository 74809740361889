import { useContext, useMemo } from 'react'

import {
  type SanityCombinedListingProduct,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import {
  getCollectionDiscounts,
  getProductDiscounts,
} from '@lib/discount/product'
import { type DocumentDiscount } from '@lib/discount/types'
import { UserContext } from '@lib/user/context'
import { type ProductPriceOptions } from './types'

/**
 * Applies discount percent to price.
 */
export const applyDiscountPercent = (
  price?: number,
  discountPercent?: number
) => {
  if (typeof price === 'undefined' || typeof discountPercent === 'undefined') {
    return price
  }

  const discountRate = discountPercent / 100
  return price * (1 - discountRate)
}

/**
 * Finds discount percent for a document in a list of document discounts.
 */
export const getDocumentPercentDiscount = (
  documentDiscounts: DocumentDiscount[],
  type: string,
  ids: string[]
) => {
  const normalizedIds = ids.filter(Boolean)
  const documentDiscount = documentDiscounts.find(
    (documentDiscount) =>
      documentDiscount.type === type &&
      normalizedIds.includes(documentDiscount.id)
  )

  return documentDiscount?.percentDiscount
}

/**
 * Gets product price and compare price.
 */
export const getProductPrices = (options: ProductPriceOptions) => {
  const percentDiscounts = [
    options.productPercentDiscount,
    options.collectionPercentDiscount,
    options.companyPercentDiscount,
  ]

  // Get variant or product price and compare price
  let price = options.variantPrice ?? options.productPrice
  let comparePrice = options.variantComparePrice ?? options.productComparePrice

  // Apply combined listing discount (applying to both price and compare price will hide the original full price)
  price = applyDiscountPercent(price, options.combinedListingPercentDiscount)
  comparePrice = applyDiscountPercent(
    comparePrice,
    options.combinedListingPercentDiscount
  )

  // Apply customer-related discounts (product discount, product category discount or company discount)
  percentDiscounts.forEach((percentDiscount) => {
    if (typeof percentDiscount === 'undefined' || percentDiscount === 0) {
      return
    }

    // Apply discount if compare price isn't already set (either from Shopify or from another discount)
    if (typeof comparePrice === 'undefined') {
      comparePrice = price
      price = applyDiscountPercent(price, percentDiscount)
    }
  })

  return [price, comparePrice] as const
}

/**
 * Hook that calculates product price.
 */
export const useProductPrice = (
  product?: SanityCombinedListingProduct,
  activeVariant?: SanityProductVariantFragment
) => {
  const { user } = useContext(UserContext)

  const [price, comparePrice] = useMemo(() => {
    const productDiscounts = getProductDiscounts(
      user?.company?.productDiscounts
    )
    const collectionDiscounts = getCollectionDiscounts(
      user?.company?.collectionDiscounts
    )
    const productPercentDiscount = getDocumentPercentDiscount(
      productDiscounts ?? [],
      'product',
      product?._id ? [product._id] : []
    )
    const collectionPercentDiscount = getDocumentPercentDiscount(
      collectionDiscounts ?? [],
      'collection',
      product?.collectionIds ?? []
    )

    return getProductPrices({
      productPrice: product?.price,
      productComparePrice: product?.comparePrice,
      variantPrice: activeVariant?.price,
      variantComparePrice: activeVariant?.comparePrice,
      productPercentDiscount,
      collectionPercentDiscount,
      companyPercentDiscount: user?.company?.percentDiscount,
    })
  }, [activeVariant, product, user])

  return [price, comparePrice] as const
}
