import { type AttributeInput } from '@data/shopify/storefront/types'
import { type LineItem } from '@lib/cart/types'
import {
  shopifyCombinedListingIdAttribute,
  shopifyCombinedListingProductIdsAttribute,
  shopifyCombinedListingProductUrlAttribute,
} from '@lib/shopify/attribute'

/**
 * Gets combined listing ID from cart line items.
 */
const getCombinedListingIdAttributeValue = (attributes: AttributeInput[]) => {
  const combinedListingIdAttribute = attributes.find(
    (attribute) => attribute.key === shopifyCombinedListingIdAttribute
  )

  return combinedListingIdAttribute?.value
}

/**
 * Gets combined listing product IDs from cart line items.
 */
const getCombinedListingProductIdsAttributeValue = (
  attributes: AttributeInput[]
) => {
  const combinedListingProductIdsAttribute = attributes.find(
    (attribute) => attribute.key === shopifyCombinedListingProductIdsAttribute
  )

  if (!combinedListingProductIdsAttribute?.value) {
    return
  }

  return combinedListingProductIdsAttribute.value
    .split(',')
    .map((productId) => Number(productId))
}

/**
 * Gets combined listing product URL from cart line items.
 */
const getCombinedListingProductUrlAttributeValue = (
  attributes: AttributeInput[]
) => {
  const combinedListingProductUrlAttribute = attributes.find(
    (attribute) => attribute.key === shopifyCombinedListingProductUrlAttribute
  )

  return combinedListingProductUrlAttribute?.value
}

/**
 * Gets product line items with combined listing items.
 */
export const getLineItemsWithCombinedListings = (lineItems: LineItem[]) => {
  return lineItems.map((lineItem) => {
    const combinedListingId = getCombinedListingIdAttributeValue(
      lineItem.attributes ?? []
    )
    const combinedListingProductIds =
      getCombinedListingProductIdsAttributeValue(lineItem.attributes ?? [])

    if (!combinedListingId || !combinedListingProductIds) {
      return lineItem
    }

    // Options based on public attributes
    const options = [...lineItem.options]
    lineItem.attributes?.forEach((attribute) => {
      if (attribute.key.startsWith('_') || !attribute.key || !attribute.value) {
        return
      }

      options.push({
        name: attribute.key,
        value: attribute.value,
        position: options.length + 1,
      })
    })

    // Product URL
    const productUrl = getCombinedListingProductUrlAttributeValue(
      lineItem.attributes ?? []
    )

    const newLineItem: LineItem = {
      ...lineItem,
      options,
      productUrl,
    }
    return newLineItem
  })
}
