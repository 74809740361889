import { gql } from '@apollo/client'

import {
  mailingAddressFragment,
  orderFragment,
  orderProductFragment,
} from '../objects/common'

export const getCustomerAddressesQuery = gql`
  ${mailingAddressFragment}

  query getCustomerAddresses($token: String!) {
    customer(customerAccessToken: $token) {
      id
      defaultAddress {
        id
      }
      addresses(first: 25) {
        edges {
          node {
            ...MailingAddressFragment
          }
        }
      }
    }
  }
`

export const getCustomerOrdersQuery = gql`
  ${orderFragment}

  query getCustomerOrders($token: String!, $limit: Int!, $after: String) {
    customer(customerAccessToken: $token) {
      id
      orders(first: $limit, after: $after, reverse: true) {
        edges {
          node {
            ...OrderFragment
          }
          cursor
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`

export const getCustomerProductsQuery = gql`
  ${orderProductFragment}

  query getCustomerProducts($token: String!) {
    customer(customerAccessToken: $token) {
      id
      orders(first: 100) {
        edges {
          node {
            ...OrderProductFragment
          }
        }
      }
    }
  }
`
