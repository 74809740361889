import cx from 'classnames'
import { type CSSProperties, type ReactNode, useId, useState } from 'react'
import { type UseFormRegisterReturn } from 'react-hook-form'

interface CheckboxProps {
  formRegister: UseFormRegisterReturn
  defaultChecked?: boolean
  errorMessage?: string
  children?: ReactNode
  className?: string
}

interface CheckboxSvgProps {
  isChecked: boolean
  className?: string
  pathStyle?: CSSProperties
}

export const CheckboxSvg = ({
  isChecked,
  className,
  pathStyle,
}: CheckboxSvgProps) => (
  <svg
    viewBox="0 0 100 100"
    className={cx(
      'w-5 h-5 block flex-none p-1 pointer-events-none border border-pageText rounded-[2px]',
      { 'bg-pageText text-pageBG': isChecked },
      className
    )}
  >
    <path
      fill="none"
      strokeWidth="13"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M12.1 52.1l24.4 24.4 53-53"
      className={cx('stroke-current text-current', 'stroke-dasharray-120', {
        'stroke-dashoffset-120': !isChecked,
        'stroke-dashoffset-0': isChecked,
      })}
      style={pathStyle}
    />
  </svg>
)

const Checkbox = ({
  formRegister,
  defaultChecked,
  errorMessage,
  children,
  className,
}: CheckboxProps) => {
  const [checked, setChecked] = useState(!!defaultChecked)

  const id = useId()

  return (
    <div className={cx('relative', className)}>
      <input
        id={`${id}-checkbox`}
        type="checkbox"
        ref={formRegister.ref}
        name={formRegister.name}
        checked={checked}
        onBlur={(event) => {
          formRegister.onBlur(event)
        }}
        onChange={(event) => {
          setChecked(event.target.checked)
          formRegister.onChange(event)
        }}
        className="absolute w-px h-px p-0 -m-px overflow-hidden whitespace-nowrap border-none opacity-0"
      />
      <label
        htmlFor={`${id}-checkbox`}
        className="relative inline-flex gap-x-2 transform-none top-auto left-auto mx-auto p-0 cursor-pointer"
      >
        <CheckboxSvg isChecked={checked} />
        <div className="flex items-center rc rc-checkbox">{children}</div>
      </label>
      {errorMessage && (
        <span role="alert" className="block mt-1 text-sm text-error">
          {errorMessage}
        </span>
      )}
    </div>
  )
}

export default Checkbox
