import { compareNumbers } from '@lib/helpers'
import { type ValueCounts } from './types'

/**
 * Gets all possible variant variations with given quantity of items.
 */
export const getAllVariantGroups = (
  unusedVariantIdCounts: ValueCounts,
  quantity: number
) => {
  const getVariantGroups = (
    availableValueCounts: ValueCounts,
    values: number[] = [],
    index = 0
  ) => {
    const availableValues = Object.entries(availableValueCounts)
      .filter(([, value]) => value > 0)
      .map(([key]) => Number(key))

    // Check if this is the last index
    if (index >= quantity - 1) {
      const results: number[][] = []

      availableValues.forEach((value) => results.push([value]))

      return results
    }

    const mergedResults: number[][] = []

    availableValues.forEach((value) => {
      const newAvailableValueCounts = {
        ...availableValueCounts,
      }
      const newValues = [...values]

      newAvailableValueCounts[`${value}`] -= 1
      newValues.push(value)

      getVariantGroups(newAvailableValueCounts, newValues, index + 1).forEach(
        (valueVariation) => mergedResults.push([value, ...valueVariation])
      )
    })

    return mergedResults
  }

  // Merge values into a string, filter only unique results
  return getVariantGroups(unusedVariantIdCounts)
    .map((values) => values.sort(compareNumbers).join(','))
    .filter((value, index, array) => array.indexOf(value) === index)
}

/**
 * Gets value counts from an array of values (e.g., { item1: 3, item2: 1, ... }).
 */
export function getValueCounts<T = string | number>(values: T[]) {
  const valueCounts: ValueCounts = {}

  values.forEach((value) => {
    valueCounts[`${value}`] = (valueCounts?.[`${value}`] ?? 0) + 1
  })

  return valueCounts
}
