import { getLocaleVariable } from '@lib/environment'
import { type Locale, getAllLocales } from '@lib/language'
import { type Parameter } from '@lib/parameters'
import { parseRequiredParameter } from '@lib/request'

type ShopifyResourceType =
  | 'Customer'
  | 'MailingAddress'
  | 'MediaImage'
  | 'Product'
  | 'ProductImage'
  | 'ProductVariant'

/**
 * Gets Shopify store ID from store domain.
 */
export const getShopifyStoreId = (shopifyStoreDomain?: string) => {
  if (!shopifyStoreDomain) {
    return
  }

  return shopifyStoreDomain.split('.')[0]
}

/**
 * Gets Shopify store domain from store ID.
 */
export const getShopifyStoreDomain = (shopifyStoreId?: string) => {
  if (!shopifyStoreId) {
    return
  }

  return `${shopifyStoreId}.myshopify.com`
}

/**
 * Gets Shopify store domain from locales.
 */
export const getShopifyStoreDomainFromLocales = (
  locales: Locale[] | Locale
) => {
  const locale = parseRequiredParameter<Locale>(locales)
  const shopifyStoreId = getLocaleVariable(
    locale,
    'NEXT_PUBLIC_SHOPIFY_STORE_ID'
  )

  return getShopifyStoreDomain(shopifyStoreId)
}

/**
 * Gets all locales that use a Shopify store ID.
 */
export const getShopifyStoreLocales = (shopifyStoreId?: string) => {
  const locales = getAllLocales()

  return locales.filter((locale) => {
    const localeShopifyStoreId = getLocaleVariable(
      locale,
      'NEXT_PUBLIC_SHOPIFY_STORE_ID'
    )

    return localeShopifyStoreId === shopifyStoreId
  })
}

/**
 * Gets locale from Shopify store domain.
 */
export const getLocaleFromShopifyStoreDomain = (
  shopifyStoreDomain?: string
) => {
  const shopifyStoreId = getShopifyStoreId(shopifyStoreDomain)
  const shopifyStoreLocales = getShopifyStoreLocales(shopifyStoreId)

  return parseRequiredParameter<Locale>(shopifyStoreLocales)
}

/**
 * Gets a Shopify global ID.
 */
export const getShopifyGlobalId = (
  resourceType: ShopifyResourceType,
  id: string | number,
  parameters?: Parameter[]
) => {
  let parameterString =
    parameters
      ?.map((parameter) => `${parameter.name}=${parameter.value}`)
      ?.join('&') ?? ''
  parameterString = parameterString.length > 0 ? `?${parameterString}` : ''

  return `gid://shopify/${resourceType}/${id}${parameterString}`
}

/**
 * Gets Shopify ID from Shopify global ID.
 */
export const parseShopifyGlobalId = (shopifyGlobalId: string) => {
  try {
    return Number(shopifyGlobalId.split('?')[0].split('/').slice(-1)[0])
  } catch {
    return
  }
}
