import {
  type ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import {
  type CartFragmentFragment,
  type CountryCode,
  type CurrencyCode,
  type ShopFragmentFragment,
} from '@data/shopify/storefront/types'
import { CartContextProvider } from './cart/context'
import { DiscountContextProvider } from './discount/context'
import { type Locale } from './language'
import { getShopifyShopIdStorageKey } from './local-storage'
import { getShopifyStoreDomainFromLocales } from './shopify/helpers'
import {
  type ShopifyClient,
  getShopifyStorefrontGraphQlClient,
} from './shopify/graphql/client'
import { getShopifyShopAndCart } from './shopify/graphql/store'

export interface LocalizedShop {
  locale: Locale
  shop?: ShopFragmentFragment
  cart?: CartFragmentFragment
}

interface ShopContextProps {
  taxRate: number
  currency?: string
  currencyCode?: CurrencyCode
  countryCode?: CountryCode
  shopifyStoreDomain?: string
  shopifyPrimaryDomain?: string
  shopifyStorefrontGraphQlClient?: ShopifyClient
  loadShop: () => Promise<void>
}

interface ShopContextProviderProps {
  locale: Locale
  site: SanitySiteFragment
  children: ReactNode
}

export const ShopContext = createContext<ShopContextProps>({
  taxRate: 0,
  loadShop: async () => {},
})

export const ShopContextProvider = ({
  locale,
  site,
  children,
}: ShopContextProviderProps) => {
  const [currency, setCurrency] = useState<string>()
  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>()
  const [countryCode, setCountryCode] = useState<CountryCode>()
  const [shopifyPrimaryDomain, setShopifyPrimaryDomain] = useState<
    string | undefined
  >(() => getShopifyStoreDomainFromLocales(locale))
  const [localizedShop, setLocalizedShop] = useState<LocalizedShop>({
    locale,
  })

  const localeInitialisedRef = useRef<Locale>()

  const shopifyStorefrontGraphQlClient = useMemo(() => {
    try {
      return getShopifyStorefrontGraphQlClient(locale)
    } catch {
      return
    }
  }, [locale])

  const shopifyStoreDomain = useMemo(
    () => getShopifyStoreDomainFromLocales(locale),
    [locale]
  )

  const taxRate = useMemo(() => site.cartSettings.taxRate ?? 0, [site])

  const loadShop = useCallback(async () => {
    // Clear shop and cart
    setLocalizedShop({
      locale,
    })

    if (typeof window === 'undefined' || !shopifyStorefrontGraphQlClient) {
      console.warn('Cannot load Shopify shop and cart details')
      return
    }

    localeInitialisedRef.current = locale

    const [shop, cart] = await getShopifyShopAndCart(
      locale,
      shopifyStorefrontGraphQlClient
    )

    setLocalizedShop({
      locale,
      shop,
      cart,
    })
  }, [locale, shopifyStorefrontGraphQlClient])

  // Get Shopify shop and cart information (update when switching language)
  useEffect(() => {
    if (localeInitialisedRef.current === locale) {
      return
    }

    loadShop()
  }, [loadShop, locale])

  useEffect(() => {
    // Save shop ID to local storage
    const shopifyShopIdStorageKey = getShopifyShopIdStorageKey(locale)
    const shopifyShopId = localizedShop.shop?.id

    if (shopifyShopId) {
      localStorage.setItem(shopifyShopIdStorageKey, shopifyShopId)
    }

    if (!shopifyShopId) {
      localStorage.removeItem(shopifyShopIdStorageKey)
    }

    if (!localizedShop.shop) {
      return
    }

    setCurrency(
      // Remove HTML tags and value placeholder "{{amount}}" from currency format string
      localizedShop.shop?.moneyFormat
        ?.replace(/<\/?[^>]+(>|$)/g, '')
        ?.replace(/\s*[{]+.*?[}]+\s*/g, '')
    )
    setCurrencyCode(localizedShop.shop?.paymentSettings?.currencyCode)
    setCountryCode(localizedShop.shop?.paymentSettings?.countryCode)
    setShopifyPrimaryDomain(
      localizedShop.shop?.primaryDomain?.host ?? shopifyStoreDomain
    )
  }, [locale, localizedShop.shop, shopifyStoreDomain])

  return (
    <ShopContext.Provider
      value={{
        taxRate,
        currency,
        currencyCode,
        countryCode,
        shopifyStoreDomain,
        shopifyPrimaryDomain,
        shopifyStorefrontGraphQlClient,
        loadShop,
      }}
    >
      <CartContextProvider localizedShop={localizedShop} site={site}>
        <DiscountContextProvider site={site}>
          {children}
        </DiscountContextProvider>
      </CartContextProvider>
    </ShopContext.Provider>
  )
}
