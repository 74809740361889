import {
  type SanityProductOption,
  type SanityProductOptionName,
  type SanityProductOptionSetting,
  type SanityProductVariantOption,
} from '@data/sanity/queries/types/product'

/**
 * Gets product option label.
 */
export const getOptionLabel = (
  optionNames: SanityProductOptionName[],
  option: SanityProductOption
) =>
  optionNames?.find(({ forOption }) => forOption === option.name)?.name ||
  option.name

/**
 * Gets default product option.
 */
export const getDefaultOption = (
  options: SanityProductOption[],
  optionSettings: SanityProductOptionSetting[]
): SanityProductVariantOption | undefined => {
  if (options.length === 0) {
    return
  }

  const firstOption = options?.[0]
  const defaultOption = {
    name: firstOption?.name,
    value: firstOption?.values?.[0],
    position: firstOption?.position,
  }

  if (optionSettings.length === 0) {
    return defaultOption
  }

  // Use first option setting to find default option
  const settingParts = optionSettings[0].forOption?.split(':')
  const name = settingParts?.[0]
  const value = settingParts?.[1]
  const position = options.find((option) => option.name === name)?.position

  if (
    typeof name === 'undefined' ||
    typeof value === 'undefined' ||
    typeof position === 'undefined'
  ) {
    return defaultOption
  }

  return { name, value, position }
}
