import { gql } from '@apollo/client'

import { cartFragment } from '../objects/cart'
import { shopFragment } from '../objects/common'

export const getShopQuery = gql`
  ${shopFragment}

  query getShop {
    shop {
      ...ShopFragment
    }
  }
`

export const getShopAndCartQuery = gql`
  ${shopFragment}
  ${cartFragment}

  query getShopAndCart($cartId: ID!) {
    shop {
      ...ShopFragment
    }
    cart(id: $cartId) {
      ...CartFragment
    }
  }
`
