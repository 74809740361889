import axios, { type AxiosResponse } from 'axios'

import { type Locale } from '@lib/language'
import {
  type CreateOrderPayload,
  type CreateOrderResponse,
} from '@lib/shopify/order'
import { type StoredUser } from '@lib/user/types'
import { type ValidateVatIdResult } from '@pages/api/tax/validate-vat-id'
import {
  type Cart,
  type CartDiscountUpdateResponse,
  type CartFormValues,
} from './types'

/**
 * Updates Shopify cart discount by calling an API route.
 */
export const updateShopifyCartDiscount = async (
  locale: Locale,
  cartId: string
) => {
  const discountUpdateResult = await axios.get<CartDiscountUpdateResponse>(
    '/api/shopify/cart-discount-update',
    {
      params: {
        cart_id: cartId,
      },
      headers: {
        'Content-Type': 'application/json',
        'X-Locale': locale,
      },
    }
  )

  if (discountUpdateResult.data?.error || !discountUpdateResult.data?.cart) {
    throw new Error(discountUpdateResult.data.error ?? 'Unknown error')
  }

  return discountUpdateResult.data.cart
}

/**
 * Creates a Shopify draft order by calling an API route.
 */
export const createShopifyDraftOrder = async (
  locale: Locale,
  cart: Cart,
  user: StoredUser
) => {
  if (!user?.customer) {
    throw new Error('Customer data missing for user')
  }

  const createOrderPayload: CreateOrderPayload = {
    cart,
    customer: user.customer,
    addresses: user.addresses ?? [],
  }
  const createDraftOrderResponse = await axios.post<
    CreateOrderResponse,
    AxiosResponse<CreateOrderResponse>,
    CreateOrderPayload
  >('/api/shopify/create-order', createOrderPayload, {
    headers: {
      'Content-Type': 'application/json',
      'X-Locale': locale,
    },
  })

  if (createDraftOrderResponse.data.error) {
    throw new Error(createDraftOrderResponse.data.error)
  }

  return createDraftOrderResponse.data
}

/**
 * Validates VAT ID by calling an API route.
 */
export const validateVatId = async (vatId: CartFormValues['vatId']) => {
  const validationResult = await axios.get<ValidateVatIdResult>(
    '/api/tax/validate-vat-id',
    {
      params: {
        id: vatId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return validationResult.data
}
