import { type SanityCompany } from '@data/sanity/queries/types/customer'
import { type LineItem, type VariantLineItem } from '@lib/cart/types'
import { getBundleDiscountItems } from './bundle'
import { getProductDiscountItems } from './product'
import { getQuantityDiscountItems } from './quantity'
import { type BundleDiscount, type QuantityDiscount } from './types'

/**
 * Gets discounts items for that apply to given variants.
 */
const getDiscountItems = (
  variantLineItems: VariantLineItem[],
  quantityDiscounts: QuantityDiscount[],
  bundleDiscounts: BundleDiscount[],
  company?: SanityCompany
) => {
  // Quantity discount
  const quantityDiscountItems = getQuantityDiscountItems(
    variantLineItems,
    quantityDiscounts
  )

  // Bundle discount
  const filteredBundleDiscounts = bundleDiscounts.filter(
    (bundleDiscount) =>
      // Remove bundle discounts that don't stack with quantity discounts
      !bundleDiscount.doNotStackWithQuantityDiscounts ||
      quantityDiscountItems.length === 0
  )
  const bundleDiscountItems = getBundleDiscountItems(
    variantLineItems,
    filteredBundleDiscounts
  )

  // Product discount
  const productDiscountItems = getProductDiscountItems(
    variantLineItems,
    company
  )

  return [
    ...quantityDiscountItems,
    ...bundleDiscountItems,
    ...productDiscountItems,
  ]
}

/**
 * Gets discount items that apply to the cart.
 */
export const getCartDiscountItems = (
  lineItems: LineItem[],
  quantityDiscounts: QuantityDiscount[],
  bundleDiscounts: BundleDiscount[],
  company?: SanityCompany
) => {
  const variantLineItems = lineItems.map((lineItem) => {
    const variantLineItem: VariantLineItem = {
      id: lineItem.variantID,
      price: lineItem.price ?? 0,
      quantity: lineItem.quantity,
      productId: lineItem.product._id,
      productCategoryId: lineItem.product.productCategoryId,
      collectionIds: lineItem.product.collectionIds,
    }

    return variantLineItem
  })

  return getDiscountItems(
    variantLineItems,
    quantityDiscounts,
    bundleDiscounts,
    company
  )
}
