import {
  addCartDeliveryAddressesMutation,
  addCartLinesMutation,
  createCartMutation,
  removeCartDeliveryAddressesMutation,
  removeCartLinesMutation,
  updateCartAttributesMutation,
  updateCartBuyerIdentityMutation,
  updateCartDeliveryAddressesMutation,
  updateCartDiscountCodesMutation,
  updateCartLinesMutation,
  updateCartNoteMutation,
} from '@data/shopify/storefront/mutations/cart'
import { getCartQuery } from '@data/shopify/storefront/queries/cart'
import {
  type AddCartDeliveryAddressesMutation,
  type AddCartDeliveryAddressesMutationVariables,
  type AddCartLinesMutation,
  type AddCartLinesMutationVariables,
  type AttributeInput,
  type CartBuyerIdentityInput,
  type CartFragmentFragment,
  type CartInput,
  type CartLineInput,
  type CartLineUpdateInput,
  type CartSelectableAddressInput,
  type CartSelectableAddressUpdateInput,
  type CreateCartMutation,
  type CreateCartMutationVariables,
  type GetCartQuery,
  type GetCartQueryVariables,
  type RemoveCartDeliveryAddressesMutation,
  type RemoveCartDeliveryAddressesMutationVariables,
  type RemoveCartLinesMutation,
  type RemoveCartLinesMutationVariables,
  type UpdateCartAttributesMutation,
  type UpdateCartAttributesMutationVariables,
  type UpdateCartBuyerIdentityMutation,
  type UpdateCartBuyerIdentityMutationVariables,
  type UpdateCartDeliveryAddressesMutation,
  type UpdateCartDeliveryAddressesMutationVariables,
  type UpdateCartDiscountCodesMutation,
  type UpdateCartDiscountCodesMutationVariables,
  type UpdateCartLinesMutation,
  type UpdateCartLinesMutationVariables,
  type UpdateCartNoteMutation,
  type UpdateCartNoteMutationVariables,
} from '@data/shopify/storefront/types'
import { type Locale } from '@lib/language'
import {
  type ShopifyClient,
  mutateShopifyStorefront,
  queryShopifyStorefront,
} from './client'
import {
  type ShopifyUserError,
  type ShopifyWarning,
  getUserErrorMessage,
  getWarningMessage,
} from './helpers'

interface CartMutationResult {
  cart?: object | null
  userErrors?: ShopifyUserError[]
  warnings?: ShopifyWarning[]
}

/**
 * Gets Shopify cart.
 */
export const getShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string
) => {
  try {
    const getCartResult = await queryShopifyStorefront<
      GetCartQuery,
      GetCartQueryVariables
    >(locale, shopifyStorefrontGraphQlClient, getCartQuery, {
      cartId,
    })

    if (!getCartResult.data) {
      return
    }

    const cartFragment = getCartResult.data.cart as
      | CartFragmentFragment
      | undefined

    return cartFragment
  } catch (error) {
    console.log(error)
  }
}

/**
 * Gets cart from cart Shopify cart mutation results.
 */
const getCartFromMutationResult = (result?: CartMutationResult | null) => {
  const userErrorMessage = getUserErrorMessage(result?.userErrors)
  const warningMessage = getWarningMessage(result?.warnings)
  const cart = result?.cart ?? undefined

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  if (warningMessage) {
    console.warn(warningMessage)
  }

  return cart as CartFragmentFragment | undefined
}

/**
 * Creates a new Shopify cart.
 */
export const createShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartInput: CartInput
) => {
  const createCartResult = await mutateShopifyStorefront<
    CreateCartMutation,
    CreateCartMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, createCartMutation, {
    cartInput,
  })

  return getCartFromMutationResult(createCartResult.data?.cartCreate)
}

/**
 * Updates buyer indentity in Shopify cart.
 */
export const updateShopifyCartBuyerIdentity = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  buyerIdentity: CartBuyerIdentityInput
) => {
  const updateCartBuyerIdentityResult = await mutateShopifyStorefront<
    UpdateCartBuyerIdentityMutation,
    UpdateCartBuyerIdentityMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, updateCartBuyerIdentityMutation, {
    cartId,
    buyerIdentity,
  })

  return getCartFromMutationResult(
    updateCartBuyerIdentityResult.data?.cartBuyerIdentityUpdate
  )
}

/**
 * Updates discount codes in Shopify cart.
 */
export const updateShopifyCartDiscountCodes = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  discountCodes: string[]
) => {
  const updateCartDiscountCodesResult = await mutateShopifyStorefront<
    UpdateCartDiscountCodesMutation,
    UpdateCartDiscountCodesMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, updateCartDiscountCodesMutation, {
    cartId,
    discountCodes,
  })

  return getCartFromMutationResult(
    updateCartDiscountCodesResult.data?.cartDiscountCodesUpdate
  )
}

/**
 * Updates attributes for Shopify cart.
 */
export const updateShopifyCartAttrbites = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  attributes: AttributeInput[]
) => {
  const updateCartAttributesResult = await mutateShopifyStorefront<
    UpdateCartAttributesMutation,
    UpdateCartAttributesMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, updateCartAttributesMutation, {
    cartId,
    attributes,
  })

  return getCartFromMutationResult(
    updateCartAttributesResult.data?.cartAttributesUpdate
  )
}

/**
 * Updates note for Shopify cart.
 */
export const updateShopifyCartNote = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  note: string
) => {
  const updateCartNoteResult = await mutateShopifyStorefront<
    UpdateCartNoteMutation,
    UpdateCartNoteMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, updateCartNoteMutation, {
    cartId,
    note,
  })

  return getCartFromMutationResult(updateCartNoteResult.data?.cartNoteUpdate)
}

/**
 * Adds new line items to Shopify cart.
 */
export const addLineItemsToShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  lines: CartLineInput[]
) => {
  const addCartLinesResult = await mutateShopifyStorefront<
    AddCartLinesMutation,
    AddCartLinesMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, addCartLinesMutation, {
    cartId,
    lines,
  })

  return getCartFromMutationResult(addCartLinesResult.data?.cartLinesAdd)
}

/**
 * Updates line items in Shopify cart.
 */
export const updateLineItemsInShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  lines: CartLineUpdateInput[]
) => {
  const updateCartLinesResult = await mutateShopifyStorefront<
    UpdateCartLinesMutation,
    UpdateCartLinesMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, updateCartLinesMutation, {
    cartId,
    lines,
  })

  return getCartFromMutationResult(updateCartLinesResult.data?.cartLinesUpdate)
}

/**
 * Removes line items from Shopify cart.
 */
export const removeLineItemsFromShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  lineIds: string[]
) => {
  const removeCartLinesResult = await mutateShopifyStorefront<
    RemoveCartLinesMutation,
    RemoveCartLinesMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, removeCartLinesMutation, {
    cartId,
    lineIds,
  })

  return getCartFromMutationResult(removeCartLinesResult.data?.cartLinesRemove)
}

/**
 * Adds delivery addresses to Shopify cart.
 */
export const addShopifyCartDeliveryAddresses = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  addresses: CartSelectableAddressInput[]
) => {
  const addCartDeliveryAddressesResult = await mutateShopifyStorefront<
    AddCartDeliveryAddressesMutation,
    AddCartDeliveryAddressesMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, addCartDeliveryAddressesMutation, {
    cartId,
    addresses,
  })

  return getCartFromMutationResult(
    addCartDeliveryAddressesResult.data?.cartDeliveryAddressesAdd
  )
}

/**
 * Updates delivery addresses in Shopify cart.
 */
export const updateShopifyCartDeliveryAddresses = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  addresses: CartSelectableAddressUpdateInput[]
) => {
  const updateCartDeliveryAddressesResult = await mutateShopifyStorefront<
    UpdateCartDeliveryAddressesMutation,
    UpdateCartDeliveryAddressesMutationVariables
  >(
    locale,
    shopifyStorefrontGraphQlClient,
    updateCartDeliveryAddressesMutation,
    {
      cartId,
      addresses,
    }
  )

  return getCartFromMutationResult(
    updateCartDeliveryAddressesResult.data?.cartDeliveryAddressesUpdate
  )
}

/**
 * Removes delivery addresses from Shopify cart.
 */
export const removeShopifyCartDeliveryAddresses = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  addressIds: string[]
) => {
  const removeCartDeliveryAddressesResult = await mutateShopifyStorefront<
    RemoveCartDeliveryAddressesMutation,
    RemoveCartDeliveryAddressesMutationVariables
  >(
    locale,
    shopifyStorefrontGraphQlClient,
    removeCartDeliveryAddressesMutation,
    {
      cartId,
      addressIds,
    }
  )

  return getCartFromMutationResult(
    removeCartDeliveryAddressesResult.data?.cartDeliveryAddressesRemove
  )
}
